import { Tab, Tabs } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { getAllSafes } from "../../../api/companies/getSafe";
import Statistic from "../Statistic/Statistic";
import { LargeLoader } from "../Loaders";
import { useNavigate, useParams } from "react-router-dom";

function AdminDashboard() {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { company } = useParams();
  const [activeTab, setActiveTab] = useState(company);
  //asd
  useEffect(() => {
    console.log(companies);
    if (companies.length === 0) {
      setLoading(true);
      getAllSafes()
        .then((res) => {
          setCompanies(res.data);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
      console.log(companies);
    }
  }, []);

  const handleSelect = (tab) => {
    navigate(`/dashboard/${tab}`);
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Funds Dashboard
          </span>
        </div>
      </div>
      <div className="panel panel-primary tabs-style-2">
        <div className=" tab-menu-heading">
          <div className="tabs-menu1">
            {/* <!-- Tabs --> */}
            {loading ? <LargeLoader /> : null}
            {error ? <h1>{error}</h1> : null}
            <Tabs
              activeKey={activeTab}
              onSelect={handleSelect}
              className=" panel-tabs main-nav-line "
            >
              <Tab eventKey={"All"} title={"All"} key={"All"}></Tab>
              {companies.map((company) => (
                <Tab
                  eventKey={company.companyCode}
                  title={company.companyCode}
                  key={company.companyCode}
                >
                  <Statistic
                    company={company.companyCode}
                    safe={company.currencies}
                    totalNet={company.totalNet}
                  />
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
