import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import React from "react";
import {
  recordDividends,
  recordSalary,
} from "../../../api/companies/recordCost";

function Dividends({ users, closeModal }) {
  const [outcomeAmount, setOutcomeAmount] = React.useState(0);
  const [details, setDetails] = React.useState("");
  const [outcomeCurrency, setOutcomeCurrency] = React.useState("");
  const [confirm, setConfirm] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [userId, setUserId] = React.useState();
  const [companyName, setCompanyName] = React.useState();

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    recordDividends(
      userId,
      outcomeAmount,
      outcomeCurrency,
      details,
      companyName
    )
      .then((response) => {
        if (response.status === 200) {
          closeModal(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setError(error.message);
        setLoading(false);
      });
  }
  function handleAmountChange(event) {
    setOutcomeAmount(event.target.value);
  }

  function handleDescriptionChange(event) {
    setDetails(event.target.value);
  }

  function handleCurrencyChange(event) {
    setOutcomeCurrency(event.target.value);
  }

  function handleConfirm() {
    setConfirm(!confirm);
  }

  function handleErrors() {
    if (outcomeAmount === 0) {
      setError("Amount cannot be 0");
    }
    if (details === "") {
      setError("Description cannot be empty");
    }
    if (outcomeCurrency === "") {
      setError("Currency cannot be empty");
    }
    setError("");
  }
  function handleCompanyChange(event) {
    setCompanyName(event.target.value);
  }

  function handleUserChange(event) {
    setUserId(event.target.value);
  }
  return (
    <Form className="form-horizontal">
      <FormGroup className="form-group">
        <div className="input-group mg-t-10">
          <Form.Select
            className="form-control"
            id="company"
            aria-required={true}
            required={true}
            onChange={handleUserChange}
          >
            <option value="">Select user</option>

            <optgroup>
              {users?.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.firstName + " " + user.lastName}
                </option>
              ))}
            </optgroup>
          </Form.Select>
        </div>
      </FormGroup>
      <FormGroup className="form-group mg-t-10">
        <div className="input-group">
          <Form.Control
            type="text"
            className="form-control"
            id="amount"
            placeholder="Amount"
            aria-required={true}
            required={true}
            onChange={handleAmountChange}
          />

          <Form.Select
            className="form-control"
            id="currency"
            aria-required={true}
            required={true}
            onChange={handleCurrencyChange}
          >
            <option value="">Select currency</option>

            <optgroup>
              <option value="USD">US Dollars</option>
              <option value="GEL">Georgian Lari</option>
              <option value="USDT">Tether USDT</option>
            </optgroup>
          </Form.Select>
        </div>
        <FormGroup className="form-group">
          <div className="input-group mg-t-10">
            <Form.Select
              className="form-control"
              id="company"
              aria-required={true}
              required={true}
              onChange={handleCompanyChange}
            >
              <option value="">Select company</option>

              <optgroup>
                <option value="CASH_TBILISI">CASH_TBILISI</option>
                <option value="CASH_BATUMI">CASH_BATUMI</option>
              </optgroup>
            </Form.Select>
          </div>
        </FormGroup>
        <FormGroup className="form-group">
          <textarea
            className="form-control"
            placeholder="Description of dividends (required)"
            rows="2"
            required={true}
            onChange={handleDescriptionChange}
          ></textarea>
        </FormGroup>
      </FormGroup>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            closeModal(false);
          }}
        >
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Pay
        </Button>
      </Modal.Footer>
    </Form>
  );
}
export default Dividends;
