import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import React from "react";

function DateSelector({ step, setStep }) {
  return (
    <ButtonGroup className="mt-3">
      <Dropdown className="btn-group">
        <Button variant="" type="button" className="btn btn-light">
          {step}
        </Button>
        <Dropdown.Toggle
          variant=""
          type="button"
          className="btn btn-light dropdown-toggle split-dropdown"
          data-bs-toggle="dropdown"
        >
          <span className="caret"></span>
          <span className="sr-only">Toggle Dropdown</span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ margin: "0px" }}
          className="dropdown-menu tx-13"
        >
          <Dropdown.Item
            className="dropdown-item"
            href="#"
            onClick={() => setStep(1)}
          >
            Day
          </Dropdown.Item>

          <Dropdown.Item
            className="dropdown-item"
            href="#"
            onClick={() => setStep(7)}
          >
            Week
          </Dropdown.Item>
          <Dropdown.Item
            className="dropdown-item"
            href="#"
            onClick={() => setStep(30)}
          >
            Month
          </Dropdown.Item>
          <Dropdown.Item
            className="dropdown-item"
            href="#"
            onClick={() => setStep(365)}
          >
            Year
          </Dropdown.Item>
          <Dropdown.Item
            className="dropdown-item"
            href="#"
            onClick={() => setStep(0)}
          >
            All
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ButtonGroup>
  );
}
export default DateSelector;
