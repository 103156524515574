import fetcher from "../../utils/Fetcher";

export class NetDocument {
  id!: string;
  number!: string;
  deal_Number!: string;
  deal_Code!: string;
  company!: string;
  type!: string;
  gel_Rate!: string;
  income!: string;
  income_Currency!: string;
  outcome!: string;
  outcome_Currency!: string;
  details!: string;
}

export class NetDocumentsFilters {
  types!: string[];
  income_Currency!: string[];
  outcome_Currency!: string[];
  net_Currency!: string[];
  payment_Method_Codes!: string[];
}

//@ts-ignore
export async function getNetDocuments(size, page, search, filter, sort) {
  return await fetcher(
    "get",
    search
      ? "/documents/search?value=" + search
      : "/documents?sort=generatedAt," +
          sort +
          "&size=" +
          size +
          "&page=" +
          page +
          filter
  );
}

export async function getOfficeManagerTable(size, page, search, filter, sort) {
  if (!filter || filter.length < 5 || !filter.includes("types")) {
    filter = "&types=cost&types=salary&types=exchange";
  }

  return await fetcher(
    "get",

    "/documents?sort=generatedAt," +
      sort +
      "&size=" +
      size +
      "&page=" +
      page +
      filter
  );
}

export async function getFilters() {
  return await fetcher("get", "/filters/NET_DOCUMENTS");
}
