import { Button, Card, InputGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getDeal } from "../../../api/deals/getDeal";
import { updateDeal } from "../../../api/deals/updateDeal";

function UpdateDeal() {
  const [dealNumber, setDealNumber] = useState("");
  const [deal, setDeal] = useState({});
  const [error, setError] = useState("");
  const [usdtValue, setUsdtValue] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [clientWallet, setClientWallet] = useState("");
  useEffect(() => {
    if (dealNumber) {
      getDeal(dealNumber)
        .then((res) => {
          setDeal(res.data);
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    }
  }, [dealNumber]);

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Update Deal</Card.Title>
      </Card.Header>
      <Card.Body>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Deal Number</InputGroup.Text>
          <input
            type="text"
            className="form-control"
            placeholder="Deal Number"
            aria-label="Deal Number"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              setDealNumber(e.target.value);
            }}
          />
          {error && <small className="text-danger">{error}</small>}
        </InputGroup>
        {deal.id && (
          <div>
            {Object.keys(deal).map((key) => (
              <Row key={key}>
                <span>{key}</span>
                <span>{deal[key]}</span>
              </Row>
            ))}
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">USDT Value</InputGroup.Text>
              <input
                type="text"
                className="form-control"
                placeholder="USDT Value"
                aria-label="USDT Value"
                aria-describedby="basic-addon1"
                onChange={(e) => {
                  setUsdtValue(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                Transaction Hash
              </InputGroup.Text>
              <input
                type="text"
                className="form-control"
                placeholder="Transaction Hash"
                aria-label="Transaction Hash"
                aria-describedby="basic-addon1"
                onChange={(e) => {
                  setTransactionHash(e.target.value);
                }}
              />
            </InputGroup>
              <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                      Client wallet
                  </InputGroup.Text>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Client wallet"
                      aria-label="Client wallet"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                          setClientWallet(e.target.value);
                      }}
                  />
              </InputGroup>
            {usdtValue && transactionHash && (
              <Button
                onClick={() => {
                  updateDeal(dealNumber, usdtValue, transactionHash, clientWallet)
                    .then((res) => {
                      alert("Deal Updated");
                      setError("");
                      getDeal(dealNumber).then((res) => {
                        setDeal(res.data);
                      });
                    })
                    .catch((err) => {
                      setError(err.message);
                    });
                }}
                variant="primary"
              >
                Update
              </Button>
            )}{" "}
            <p className="text-danger">{error}</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
export default UpdateDeal;
