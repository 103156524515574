import React, { useEffect, useState } from "react";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { getReferralConfig } from "../../../api/config/config";

function ReferralConfig() {
  const [settings, setSettings] = useState();
  useEffect(() => {
    getReferralConfig().then((res) => {
      console.log(res);
      setSettings(res.data);
    });
  }, []);

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            EMPTY PAGE
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" href="#">
              Pages
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumb-item "
              active
              aria-current="page"
            >
              Empty Page
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb -->

					<!-- row --> */}
      <Row>
        <Col md={12} xl={12} xs={12} sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h6">Referral program configuration</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                {settings &&
                  Object.keys(settings).map((key, index) => {
                    return (
                      <Col
                        md={4}
                        xl={4}
                        xs={4}
                        sm={4}
                        key={index}
                        className={"mg-r-10"}
                      >
                        <Card.Header>
                          <Card.Title as="h6">{key} </Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <h6>{settings[key]}</h6>
                        </Card.Body>
                      </Col>
                    );
                    /*<div key={index}>
                                          <h6>{item.name}</h6>
                                          <p>Code : {item.paymentCode}</p>
                                          <p>Status : {item.active ? "Enabled" : "Disabled"}</p>
                                          <p>Buy fee : {item.buyFee}</p>
                                          <p>Sell fee : {item.sellFee}</p>
                                          <p>Priority : {item.priority}</p>
                                        </div>*/
                  })}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <!-- row closed --> */}
    </div>
  );
}

ReferralConfig.propTypes = {};

ReferralConfig.defaultProps = {};

export default ReferralConfig;
