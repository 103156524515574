import CookiesStore from "../../utils/CookiesStore";
import axios from "axios";
import { API_URL } from "../Constants";

export class User {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export class UserFilters {
  countryCodes: string[];
  emailVerified: boolean;
  locked: boolean;
  kycStatuses: string[];
  providers: string[];
}

export async function getUsers(size, page, search, filter, sort, role) {
  const jwtToken = CookiesStore.getJwtToken();

  try {
    const response = await axios({
      method: "get",
      url: search
        ? API_URL + "/users/search?value=" + search
        : API_URL +
          "/users?" +
          "roles=" +
          role +
          "&sort=registeredAt," +
          sort +
          "&size=" +
          size +
          "&page=" +
          page +
          filter,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    console.log(response.data);

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getFilters() {
  const jwtToken = CookiesStore.getJwtToken();
  try {
    const response = await axios({
      method: "get",
      url: API_URL + "/filters/USERS",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
