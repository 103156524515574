import fetcher from "../../utils/Fetcher";

export async function getCompaniesConfig() {
  // @ts-ignore
  return await fetcher("get", "/configs/payment-methods", {}, {});
}
export async function getCurrenciesConfig() {
  // @ts-ignore
  return await fetcher("get", "/configs/currencies", {}, {});
}
export async function getFeaturesConfig() {
  // @ts-ignore
  return await fetcher("get", "/configs/features-toggle", {}, {});
}
export async function getReferralConfig() {
  // @ts-ignore
  return await fetcher("get", "/configs/referral-program", {}, {});
}
export async function getConfig(name) {
  // @ts-ignore
  return await fetcher("get", `/configs/${name}`, {}, {});
}
export async function updatePaymentMethodsConfig(company, data) {
  // @ts-ignore
  return await fetcher("put", "/configs/payment-methods/" + company, data, {});
}

export async function getInfoBoxMessages() {
  // @ts-ignore
  return await fetcher("get", "/configs/messages/infobox", {}, {});
}

export async function updateInfoBoxMessages(data) {
  // @ts-ignore
  return await fetcher("put", "/configs/messages/infobox", data, {});
}

export async function removeInfoBoxMessages() {
  // @ts-ignore
  return await fetcher("delete", "/configs/messages/infobox/", {}, {});
}

export async function getGlobalConfig() {
  // @ts-ignore
  return await fetcher("get", "/configs/global", {}, {});
}
