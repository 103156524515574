import React, { useEffect, useState } from "react";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { getFilters, getReferrals } from "../../../api/users/getReferrals";
import TableView from "../EmptyPage/TableView";
import { updateUser } from "../../../api/users/updateUser";
import { getUsers } from "../../../api/users/getUsers";
import { updateRef } from "../../../api/users/updateRef";

function Referrals() {
  const [users, setUsers] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [sort, setSort] = React.useState("desc");

  useEffect(() => {
    getFilters().then((response) => {});
  }, []);

  useEffect(() => {
    getReferrals(pageSize, currentPage, search, filter, sort).then(
      (response) => {
        setUsers(response.data.content);

        if (users.length === 0) {
          // setusers(response.content);

          setUsers(response.data.content);
          setTotalElements(response.data.totalElements);
          setTotalPages(response.data.totalPages);
        } else if (users.length > 0) {
          console.log("users.length > 0");
          setTotalElements(response.data.totalElements);
          setTotalPages(response.data.totalPages);
          return setUsers(response.data.content);
          /*  setusers(users.concat(response.content));
                                                    setTotalElements(response.totalElements);
                                                    setTotalPages(response.totalPages);*/
        } else {
        }
      }
    );
  }, [
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    search,
    setSearch,
    filter,
    setFilter,
    sort,
    setSort,
  ]);
  async function updateRefInfo(userId, data) {
    await updateRef(userId, data).then((response) => {
      if (response.status === 200) {
        getReferrals(pageSize, currentPage, search, filter, sort).then(
          (response) => {
            setUsers(response.data.content);
          }
        );
      }
    });
  }
  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Referrals : {totalElements}
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" href="#">
              Transactions
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumb-item "
              active
              aria-current="page"
            >
              Users
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb -->

					<!-- row --> */}
      <Row>
        <Col md={12} xl={12} xs={12} sm={12}>
          <Card>
            <Card.Body>
              <div>
                {/*  <Savetable data={users} headers={["id", "type", "status", "userNumber", "paymentMethodCode"]}/>*/}
                {/*<BasicTable
                  data={users}
                  headers={[
                    {
                      Header: "user id",
                      accessor: "userId",
                      className: "borderrigth",
                    },
                    {
                      Header: "referred By",
                      accessor: "referredBy",
                      className: "borderrigth",
                    },

                    {
                      Header: "referralRewardsBalance",
                      accessor: "referralRewardsBalance",
                      className: "borderrigth",
                    },
                    {
                      Header: "totalReferrals",
                      accessor: "totalReferrals",
                      className: "borderrigth",
                    },
                    {
                      Header: "weekReferrals",
                      accessor: "weekReferrals",
                      className: "borderrigth",
                    },
                    {
                      Header: "referralRewardsWallet",
                      accessor: "referralRewardsWallet",
                      className: "borderrigth",
                    },
                  ]}
                  size={pageSize}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setPage={setCurrentPage}
                  setSize={setPageSize}
                  setSearch={setSearch}
                  filters={filters}
                  currentFilter={filter}
                  setFilter={setFilter}
                  sort={sort}
                  setSort={setSort}
                  searchOptions={"User ID"}
                />*/}
                <TableView
                  data={users}
                  columnNames={[
                    "userId",
                    "referredBy",
                    "referralRewardsBalance",
                    "totalReferrals",
                    "weekReferrals",
                    "referralRewardsWallet",
                  ]}
                  editableFields={["firstLevelReferral", "secondLevelReferral"]}
                  size={pageSize}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setPage={setCurrentPage}
                  setSize={setPageSize}
                  setSearch={setSearch}
                  filters={filters}
                  currentFilter={filter}
                  setFilter={setFilter}
                  sort={sort}
                  setSort={setSort}
                  searchOptions={"User ID"}
                  update={updateRefInfo}
                  type={"referrals"}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <!-- row closed --> */}
    </div>
  );
}

Referrals.propTypes = {};

Referrals.defaultProps = {};

export default Referrals;
