import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
} from "react-bootstrap";
import { BasicTable } from "../../Tables/DataTables/Basictable";

import {
  getNetDocuments,
  getFilters,
  NetDocumentsFilters,
  getOfficeManagerTable,
} from "../../../api/netDocs/getNetDocs.tsx";
import TableView from "../EmptyPage/TableView";
import Users from "../Users/Users";
import Header from "../../../shade/Header/Header";

function OfficeManagerTable() {
  const [netDocs, setNetDocs] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = React.useState([]);
  const [filter, setFilter] = React.useState(
    "&types=cost&types=salary&types=exchange"
  );
  const [sort, setSort] = React.useState("desc");
  const [showUsers, setShowUsers] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  useEffect(() => {
    getFilters().then((response) => {
      console.log(response);

      let filters = new NetDocumentsFilters();
      filters.types = ["cost", "salary", "exchange"];

      filters.payment_Method_Codes = ["CASH_TBILISI", "CASH_BATUMI"];
      filters.income_Currency = ["gel", "usd", "usdt"];
      filters.outcome_Currency = ["gel", "usd", "usdt"];
      filters.net_Currency = [];

      setFilters(filters);
    });
  }, [filter]);

  useEffect(() => {
    getOfficeManagerTable(pageSize, currentPage, search, filter, sort).then(
      (response) => {
        setNetDocs(response.data.content);

        if (netDocs.length === 0) {
          // setnetDocuments(response.content);

          setNetDocs(response.data.content);
          setTotalElements(response.data.totalElements);
          setTotalPages(response.data.totalPages);
        } else if (netDocs.length > 0) {
          console.log("netDocuments.length > 0");
          setTotalElements(response.data.totalElements);
          setTotalPages(response.data.totalPages);
          return setNetDocs(response.data.content);
          /*  setnetDocuments(netDocuments.concat(response.content));
                                        setTotalElements(response.totalElements);
                                        setTotalPages(response.totalPages);*/
        } else {
          console.log(netDocs);
          console.log(totalElements);
          console.log(totalPages);
        }
      }
    );
  }, [
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    search,
    setSearch,
    filter,
    setFilter,
    sort,
    setSort,
  ]);
  useEffect(() => {
    console.log(filter);
  }, [filter]);

  return (
    <div>
      <Header />
      {/* <!-- breadcrumb --> */}
      <ButtonGroup aria-label="Basic example" className={"mg-t-100 wd-100p"}>
        <Button
          variant={showUsers ? "secondary" : "primary"}
          onClick={() => {
            setShowDocuments(false);
            setShowUsers(!showUsers);
          }}
        >
          Users
        </Button>
        <Button
          variant={showDocuments ? "info" : "warning"}
          onClick={() => {
            setShowUsers(false);
            setShowDocuments(!showDocuments);
          }}
        >
          Documents
        </Button>
      </ButtonGroup>

      {/* <!-- /breadcrumb -->

					<!-- row --> */}
      <Row className={"pd-20"}>
        {showDocuments ? (
          <Col md={12} xl={12} xs={12} sm={12}>
            <Card>
              <Card.Header>
                <div className="breadcrumb-header justify-content-between">
                  <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">
                      Documents : {totalElements}
                    </span>
                  </div>
                  <div className="justify-content-center mt-2">
                    <Breadcrumb className="breadcrumb">
                      <Breadcrumb.Item
                        className="breadcrumb-item tx-15"
                        href="#"
                      >
                        Transactions
                      </Breadcrumb.Item>
                      <Breadcrumb.Item
                        className="breadcrumb-item "
                        active
                        aria-current="page"
                      >
                        Documents
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div>
                  {/*  <Savetable data={netDocuments} headers={["id", "type", "status", "userNumber", "paymentMethodCode"]}/>*/}
                  <TableView
                    data={netDocs}
                    columnNames={[
                      "number",
                      "generatedAt",
                      "type",
                      "details",
                      "income",
                      "incomeCurrency",
                      "outcome",
                      "outcomeCurrency",
                      "company",
                      "gelRate",
                    ]}
                    editableFields={[]}
                    size={pageSize}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setPage={setCurrentPage}
                    setSize={setPageSize}
                    filters={filters}
                    currentFilter={filter}
                    setFilter={setFilter}
                    sort={sort}
                    setSort={setSort}
                    type={"office"}
                    disableSearch={true}
                  />

                  {/*  <BasicTable
                  data={netDocs}
                  headers={[
                    {
                      Header: "number",
                      accessor: "number",
                      className: "borderrigth",
                    },
                    {
                      Header: "type",
                      accessor: "type",
                      className: "borderrigth",
                    },

                    {
                      Header: "company",
                      accessor: "company",
                      className: "borderrigth",
                    },
                    {
                      Header: "income",
                      accessor: "income",
                      className: "borderrigth",
                    },
                    {
                      Header: "income currency",
                      accessor: "incomeCurrency",
                      className: "borderrigth",
                    },
                    {
                      Header: "outcome",
                      accessor: "outcome",
                      className: "borderrigth",
                    },
                    {
                      Header: "outcome currency",
                      accessor: "outcomeCurrency",
                      className: "borderrigth",
                    },

                    {
                      Header: "details",
                      accessor: "details",
                      className: "borderrigth",
                    },

                    {
                      Header: "gel Rate",
                      accessor: "gelRate",
                      className: "borderrigth",
                    },
                  ]}
                  size={pageSize}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setPage={setCurrentPage}
                  setSize={setPageSize}
                  setSearch={setSearch}
                  filters={filters}
                  currentFilter={filter}
                  setFilter={setFilter}
                  sort={sort}
                  setSort={setSort}
                />*/}
                </div>
              </Card.Body>
            </Card>
          </Col>
        ) : null}
        {showUsers ? (
          <Col md={12} xl={12} xs={12} sm={12}>
            <Users
              role={"ROLE_WORKER,ROLE_CRYPTO_SENDER"}
              disableSearch={true}
            />
          </Col>
        ) : null}
      </Row>
      {/* <!-- row closed --> */}
    </div>
  );
}

OfficeManagerTable.propTypes = {};

OfficeManagerTable.defaultProps = {};

export default OfficeManagerTable;
