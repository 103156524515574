import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, InputGroup, Row } from "react-bootstrap";
import { Datepicker } from "./DealsStat";
import DateSelector from "../../DateSelector";
import { clientsStat } from "../../../api/companies/getStat";
import DatePicker from "react-datepicker";

const UsersStat = () => {
  const [step, setStep] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = useState(() => {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth());
    return currentDate.getTime();
  });
  const [endDate, setEndDate] = useState(new Date());
  useEffect(() => {
    clientsStat(
      new Date(startDate).toISOString().substring(0, 10),
      new Date(endDate).toISOString().substring(0, 10),
      step
    ).then((res) => {
      setData(res.data);
    });
  }, [step, startDate, endDate]);
  const chartClients = {
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      xaxis: {
        categories: data?.map((d, i) =>
          step === 1
            ? new Date(d.startDate).toLocaleDateString().substring(0, 5)
            : new Date(d.startDate).toLocaleDateString().substring(0, 5) +
              "-" +
              new Date(d.endDate).toLocaleDateString().substring(0, 5)
        ),
      },
      yaxis: {
        title: {
          text: "Counts",
        },
        min: 0,
      },
      legend: {
        position: "bottom",
      },
    },
    series: [
      {
        name: "Registered",
        data: data.map((d) => d.registered),
        color: "#FFA41B",
      },
      {
        name: "Verified",
        data: data.map((d) => d.verified),
        color: "#FF4560",
      },
      {
        name: "Passed KYC",
        data: data.map((d) => d.passedKYC),
        color: "#4bff45",
      },
      {
        name: "With referral",
        data: data.map((d) => d.registeredByReferral),
        color: "#45d4ff",
      },
      {
        name: "Email",
        data: data.map((d) => d.registeredBy.email),
        color: "#949494",
      },
      {
        name: "Google",
        data: data.map((d) => d.registeredBy.google),
        color: "#ffffff",
      },
    ],
  };
  /* const data = [
      { date: "2022-12-31", newUsers: 150, verifiedUsers: 120 },
      { date: "2023-01-01", newUsers: 200, verifiedUsers: 180 },
      { date: "2023-01-02", newUsers: 140, verifiedUsers: 100 },
      { date: "2023-01-03", newUsers: 180, verifiedUsers: 140 },
      { date: "2023-01-04", newUsers: 170, verifiedUsers: 130 },
      { date: "2023-01-05", newUsers: 210, verifiedUsers: 190 },
      { date: "2023-01-06", newUsers: 160, verifiedUsers: 120 },
    ];*/

  const series = [
    {
      name: "New Users",
      data: data.map((item) => ({ x: item.startDate, y: item.registered })),
      color: "#FFA41B",
    },
    {
      name: "Verified Users",
      data: data.map((item) => ({ x: item.startDate, y: item.passedKYC })),
      color: "#FF4560",
    },
  ];

  const options = {
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      xaxis: {
        categories: data.map((d, i) => d.startDate),
      },
      yaxis: {
        title: {
          text: "Values",
        },
        min: 0,
      },
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <div>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Clients Dashboard
          </span>
        </div>
      </div>
      <Card>
        <Card.Header
          className=" border-bottom-0 d-flex"
          style={{ justifyContent: "space-between" }}
        >
          <h3 className="card-title mb-2">Users</h3>
        </Card.Header>
        <Card.Body>
          <Row className={"justify-content-between"}>
            <Col>
              <Row>
                <Col xl={3} lg={4} md={5} xs={100} className="">
                  From
                  <InputGroup className="input-group reactdate-pic">
                    <InputGroup.Text className="input-group-text">
                      <i className="bi bi-calendar-heart"></i>
                    </InputGroup.Text>
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </InputGroup>
                </Col>
                <Col xl={3} lg={4} md={5} xs={100} className="">
                  To
                  <InputGroup className="input-group reactdate-pic">
                    <InputGroup.Text className="input-group-text">
                      <i className="bi bi-calendar-heart"></i>
                    </InputGroup.Text>
                    <DatePicker
                      className="form-control"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
            <DateSelector step={step} setStep={setStep} />
          </Row>
          <ReactApexChart
            className="mt-4"
            options={chartClients.options}
            series={chartClients.series}
            type="bar"
            height={350}
          />
        </Card.Body>
        <Card.Footer>
          <Col>
            <Row>
              <Col xl={3} lg={5} md={7} xs={9}>
                <Card className="sales-card bd bd radius-5">
                  <Row>
                    <div className="col-8 ">
                      <div className="ps-4 pt-4 pe-3 pb-4">
                        <div className="">
                          <h6 className="mb-2 tx-12 ">Total new users</h6>
                        </div>
                        <div className="pb-0 mt-0">
                          <div className="d-flex">
                            <h4 className="tx-20 font-weight-semibold mb-2">
                              {data.reduce(
                                (acc, item) => acc + item.registered,
                                0
                              )}
                            </h4>
                          </div>
                          <p className="mb-0 tx-12 text-muted">
                            Growth
                            <i className="fa fa-caret-up mx-2 text-success"></i>
                            <span className="text-success font-weight-semibold">
                              ---%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="circle-icon bg-info-transparent text-center align-self-center overflow-hidden">
                        <i className="bi bi-person tx-18 text-info"></i>
                      </div>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xl={3} lg={5} md={7} xs={9}>
                <Card className="sales-card bd bd radius-5">
                  <Row>
                    <div className="col-8">
                      <div className="ps-4 pt-4 pe-3 pb-4">
                        <div className="">
                          <h6 className="mb-2 tx-12 ">Verified users</h6>
                        </div>
                        <div className="pb-0 mt-0">
                          <div className="d-flex">
                            <h4 className="tx-20 font-weight-semibold mb-2">
                              {data.reduce(
                                (acc, item) => acc + item.verified,
                                0
                              )}
                            </h4>
                          </div>
                          <p className="mb-0 tx-12 text-muted">
                            Growth
                            <i className="fa fa-caret-up mx-2 text-secondary"></i>
                            <span className="text-success font-weight-semibold">
                              ---%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                        <i className="bi bi-person-check tx-18 text-primary"></i>
                      </div>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xl={3} lg={5} md={7} xs={9}>
                <Card className="sales-card bd bd radius-5">
                  <Row>
                    <div className="col-8">
                      <div className="ps-4 pt-4 pe-3 pb-4">
                        <div className="">
                          <h6 className="mb-2 tx-12 ">Passed KYC</h6>
                        </div>
                        <div className="pb-0 mt-0">
                          <div className="d-flex">
                            <h4 className="tx-20 font-weight-semibold mb-2">
                              {data.reduce(
                                (acc, item) => acc + item.passedKYC,
                                0
                              )}
                            </h4>
                          </div>
                          <p className="mb-0 tx-12 text-muted">
                            Growth
                            <i className="fa fa-caret-up mx-2 text-secondary"></i>
                            <span className="text-success font-weight-semibold">
                              ---%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                        <i className="bi bi-person-check tx-18 text-primary"></i>
                      </div>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xl={3} lg={5} md={7} xs={9}>
                <Card className="sales-card bd bd radius-5">
                  <Row>
                    <div className="col-8">
                      <div className="ps-4 pt-4 pe-3 pb-4">
                        <div className="">
                          <h6 className="mb-2 tx-12 ">Conversion</h6>
                        </div>
                        <div className="pb-0 mt-0">
                          <div className="d-flex">
                            <h4 className="tx-20 font-weight-semibold mb-2">
                              {(data.reduce(
                                (acc, item) => acc + item.passedKYC,
                                0
                              ) /
                                data.reduce(
                                  (acc, item) => acc + item.registered,
                                  0
                                )) *
                                100}
                              %
                            </h4>
                          </div>
                          <p className="mb-0 tx-12 text-muted">
                            Growth
                            <i className="fa fa-caret-up mx-2 text-secondary"></i>
                            <span className="text-success font-weight-semibold">
                              ---%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="circle-icon bg-purple-transparent text-center align-self-center overflow-hidden">
                        <i className="bi bi-percent tx-18 text-purple"></i>
                      </div>
                    </div>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3} lg={5} md={7} xs={9}>
                <Card className="sales-card bd bd radius-5">
                  <Row>
                    <div className="col-8 ">
                      <div className="ps-4 pt-4 pe-3 pb-4">
                        <div className="">
                          <h6 className="mb-2 tx-12 ">With referral</h6>
                        </div>
                        <div className="pb-0 mt-0">
                          <div className="d-flex">
                            <h4 className="tx-20 font-weight-semibold mb-2">
                              {data.reduce(
                                (acc, item) => acc + item.registeredByReferral,
                                0
                              )}
                            </h4>
                          </div>
                          <p className="mb-0 tx-12 text-muted">
                            Growth
                            <i className="fa fa-caret-up mx-2 text-success"></i>
                            <span className="text-success font-weight-semibold">
                              ---%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="circle-icon bg-info-transparent text-center align-self-center overflow-hidden">
                        <i className="bi bi-person tx-18 text-info"></i>
                      </div>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xl={3} lg={5} md={7} xs={9}>
                <Card className="sales-card bd bd radius-5">
                  <Row>
                    <div className="col-8">
                      <div className="ps-4 pt-4 pe-3 pb-4">
                        <div className="">
                          <h6 className="mb-2 tx-12 ">By email</h6>
                        </div>
                        <div className="pb-0 mt-0">
                          <div className="d-flex">
                            <h4 className="tx-20 font-weight-semibold mb-2">
                              {data.reduce(
                                (acc, item) => acc + item.registeredBy.email,
                                0
                              )}
                            </h4>
                          </div>
                          <p className="mb-0 tx-12 text-muted">
                            Growth
                            <i className="fa fa-caret-up mx-2 text-secondary"></i>
                            <span className="text-success font-weight-semibold">
                              ---%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                        <i className="bi bi-person-check tx-18 text-primary"></i>
                      </div>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xl={3} lg={5} md={7} xs={9}>
                <Card className="sales-card bd bd radius-5">
                  <Row>
                    <div className="col-8">
                      <div className="ps-4 pt-4 pe-3 pb-4">
                        <div className="">
                          <h6 className="mb-2 tx-12 ">By Google</h6>
                        </div>
                        <div className="pb-0 mt-0">
                          <div className="d-flex">
                            <h4 className="tx-20 font-weight-semibold mb-2">
                              {data.reduce(
                                (acc, item) => acc + item.registeredBy.google,
                                0
                              )}
                            </h4>
                          </div>
                          <p className="mb-0 tx-12 text-muted">
                            Growth
                            <i className="fa fa-caret-up mx-2 text-secondary"></i>
                            <span className="text-success font-weight-semibold">
                              ---%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                        <i className="bi bi-person-check tx-18 text-primary"></i>
                      </div>
                    </div>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default UsersStat;
