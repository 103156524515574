import { Card, Col, Row } from "react-bootstrap";
import { FormEditorstyle1 } from "./BasicEditor";
import { useEffect } from "react";
import { getGlobalConfig } from "../../../api/config/config";

function MessagesConfig() {
  useEffect(() => {
    getGlobalConfig()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    return () => {
      console.log("unmount");
    };
  }, []);
  return (
    <Row className={"mg-t-20"}>
      <Col md={12}>
        <Card>
          <Card.Header className="border-bottom-0">
            <h3 className="card-title">Info Box message</h3>
          </Card.Header>
          <Card.Body>
            <FormEditorstyle1 />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default MessagesConfig;
