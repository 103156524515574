import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import React from "react";
import recordCost, { recordSalary } from "../../../api/companies/recordCost";
import { SmallLoader } from "../Loaders";

function SalaryModal({ closeModal, worker }) {
  const [outcomeAmount, setOutcomeAmount] = React.useState(0);
  const [details, setDetails] = React.useState("");
  const [outcomeCurrency, setOutcomeCurrency] = React.useState("");
  const [confirm, setConfirm] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [userId, setUserId] = React.useState(worker?.id);
  const [companyName, setCompanyName] = React.useState(worker?.companies[0]);
  console.log(worker);

  function handleSubmit(event) {
    console.log(worker);
    event.preventDefault();
    setLoading(true);
    recordSalary(userId, outcomeAmount, outcomeCurrency, details, companyName)
      .then((response) => {
        if (response.status === 200) {
          closeModal(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setError(error.message);
        setLoading(false);
      });
  }

  function handleAmountChange(event) {
    setOutcomeAmount(event.target.value);
  }

  function handleDescriptionChange(event) {
    setDetails(event.target.value);
  }

  function handleCurrencyChange(event) {
    setOutcomeCurrency(event.target.value);
  }

  function handleConfirm() {
    setConfirm(!confirm);
  }

  function handleErrors() {
    if (outcomeAmount === 0) {
      setError("Amount cannot be 0");
    }
    if (details === "") {
      setError("Description cannot be empty");
    }
    if (outcomeCurrency === "") {
      setError("Currency cannot be empty");
    }
    setError("");
  }
  function handleCompanyChange(event) {
    setCompanyName(event.target.value);
  }
  return (
    <Form className="form-horizontal">
      <FormGroup className="form-group">
        <div className="input-group">
          <Form.Control
            type="text"
            className="form-control"
            id="amount"
            placeholder="Amount"
            aria-required={true}
            required={true}
            onChange={handleAmountChange}
          />

          <Form.Select
            className="form-control"
            id="currency"
            aria-required={true}
            required={true}
            onChange={handleCurrencyChange}
          >
            <option value="">Select currency</option>

            <optgroup>
              <option value="USD">US Dollars</option>
              <option value="GEL">Georgian Lari</option>
              <option value="USDT">Tether USDT</option>
            </optgroup>
          </Form.Select>
        </div>
      </FormGroup>
      {worker?.companies.length > 1 ? (
        <FormGroup className="form-group">
          <div className="input-group">
            <Form.Select
              className="form-control"
              id="company"
              aria-required={true}
              required={true}
              onChange={handleCompanyChange}
            >
              <option value="">Select company</option>
              {worker?.companies.map((company) => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </Form.Select>
          </div>
        </FormGroup>
      ) : null}
      <FormGroup className="form-group">
        <textarea
          className="form-control"
          placeholder="Description of salary (required)"
          rows="2"
          required={true}
          onChange={handleDescriptionChange}
        ></textarea>
      </FormGroup>
      <FormGroup className="form-group"></FormGroup>
      <FormGroup className="form-group mb-0 justify-content-end">
        <div className="checkbox">
          <div className="custom-checkbox custom-control">
            <Form.Control
              aria-required={true}
              type="checkbox"
              data-checkboxes="mygroup"
              className="custom-control-input"
              id="checkbox-2"
              required={true}
              onChange={handleConfirm}
            />
            <Form.Label
              htmlFor="checkbox-2"
              className="custom-control-label mt-1"
            >
              Yes I checked and everything is correct. I am acknowledge and
              agree with{" "}
              <u className={"tx-semibold text-warning"}>
                Rules, Bonuses and Penalties policy
              </u>
            </Form.Label>
          </div>
        </div>
      </FormGroup>
      <FormGroup className="form-group mb-0 mt-3 justify-content-start">
        <div>
          {error !== "" ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            ""
          )}
          <Modal.Footer>
            <Button
              disabled={error !== ""}
              variant=""
              type="submit"
              className={
                !(
                  confirm &&
                  details !== "" &&
                  outcomeAmount !== 0 &&
                  outcomeCurrency !== ""
                )
                  ? "btn me-2 btn-primary disabled"
                  : "btn btn-primary"
              }
              onClick={(e) => {
                handleErrors();
                if (confirm && details !== "" && outcomeAmount !== 0) {
                  handleSubmit(e);
                }
              }}
            >
              {loading ? <SmallLoader /> : "Record"}
            </Button>
            <Button
              variant="secondary"
              onClick={(e) => {
                closeModal(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      </FormGroup>
    </Form>
  );
}

export default SalaryModal;
