import React, { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  OverlayTrigger,
  Pagination,
  Popover,
} from "react-bootstrap";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import CalendarFilter from "../../Calendar/CalendarFilter";
import { ToastContainer } from "react-toastify";

export const BasicTable = ({
  data,
  headers,
  totalPages,
  currentPage,
  size,
  setPage,
  setSize,
  setSearch,
  filters,
  currentFilter,
  setFilter,
  sort,
  setSort,
  searchOptions,
  actions,
  editableFields,
}) => {
  console.log(filters);
  const tableInstance = useTable(
    {
      columns: headers,
      data: data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps, // table props from react-table
    headerGroups, // headerGroups, if your table has groupings
    getTableBodyProps, // table body props from react-table
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    /*state,*/
    page, // use, page or rows
    setPageSize,
  } = tableInstance;

  /* const {globalFilter, pageIndex, pageSize} = state;*/

  const [modalShow, setModalShow] = React.useState(false);
  const [currentRowId, setCurrentRowId] = React.useState(null);
  const [from, setFrom] = React.useState("");
  const [openFrom, setOpenFrom] = React.useState(false);
  const [to, setTo] = React.useState("");
  const [openTo, setOpenTo] = React.useState(false);
  const pageNumbersArray = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbersArray.push(i);
  }

  function openCalendar(e, type) {
    if (type === "from") {
      setOpenFrom(!openFrom);
    } else {
      setOpenTo(!openTo);
    }
  }

  useEffect(() => {
    if (from) {
      setFilter(currentFilter + "&startDate=" + new Date(from).getTime());
    }
    if (to) {
      setFilter(currentFilter + "&endDate=" + new Date(to).getTime());
    }
  }, [from, to]);
  return (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: "column",
        }}
      >
        <div>
          <input
            placeholder={
              to
                ? "From " + from + " To " + to
                : from
                ? "From " + from
                : "From - To"
            }
            className={"form-control mb-3 wd-xs-300"}
            value={
              to
                ? "From " + from + " To " + to
                : from
                ? "From " + from
                : "From - To"
            }
            onClick={(e) => {
              setOpenFrom(!openFrom);
            }}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
          />
          <CalendarFilter
            open={openFrom}
            setOpen={setOpenFrom}
            setStartDate={setFrom}
            setEndDate={setTo}
            startDate={from}
            endDate={to}
          />
        </div>
        <div className="">
          <input
            type="text"
            className={"form-control wd-xs-700"}
            placeholder={"Search by " + searchOptions}
            onChange={(e) => setSearch(e.target.value)}
          />
          <span className="input-group-append"></span>
        </div>

        <ButtonGroup className="ms-2 mt-2 mb-2" style={{ marginLeft: "0" }}>
          {filters
            ? Object.keys(filters).map((filter, index) => {
                return (
                  <Dropdown key={index}>
                    <Dropdown.Toggle
                      variant=""
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="btn ripple btn-rounded btn-outline-primary"
                      data-bs-toggle="dropdown"
                      id="dropdownMenuButton12"
                      type="button"
                      style={{
                        marginBottom: "0.5rem",
                        marginRight: "0.5rem",
                        textTransform: "capitalize",
                      }}
                    >
                      {filter.replaceAll("_", " ")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{ margin: "0px" }}
                      className="dropdown-menu tx-13"
                    >
                      {filters[filter].map((item, index) => {
                        return (
                          <Dropdown.Item
                            className="dropdown-item"
                            href="#"
                            key={index}
                            onClick={(e) => {
                              console.log("changed");
                              console.log(filter);
                              console.log(item);
                              if (currentFilter.length !== 0) {
                                setFilter(
                                  currentFilter +
                                    "&" +
                                    filter.replaceAll("_", "") +
                                    "=" +
                                    item
                                );
                              } else {
                                setFilter(
                                  currentFilter +
                                    "&" +
                                    filter.replaceAll("_", "") +
                                    "=" +
                                    item
                                );
                              }
                            }}
                          >
                            {item}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              })
            : null}

          <div>
            {currentFilter?.length > 0 ? (
              <Button
                variant=""
                className="btn me-2 btn-secondary"
                onClick={(e) => {
                  setFilter("");
                }}
              >
                Clear Filter
              </Button>
            ) : null}
          </div>

          <div
            className="ms-auto"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={(e) => {
              setSort(sort === "asc" ? "desc" : "asc");
            }}
          >
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={
                <Popover style={{ margin: "0px" }}>
                  <Popover.Header as="h3">
                    Sort {sort === "asc" ? "Ascending" : "Descending"}
                  </Popover.Header>
                </Popover>
              }
            >
              <Button variant="" className=" btn btn-primary">
                <i
                  className={
                    sort === "desc" ? "bi bi-sort-down" : "bi bi-sort-up"
                  }
                ></i>
              </Button>
            </OverlayTrigger>
          </div>
        </ButtonGroup>
        <div className="d-flex flex-row justify-content-start">
          {currentFilter
            ? currentFilter.split("&").map((item, index) => {
                console.log(item);
                let filterName = item.split("=")[0];
                return item ? (
                  <Button
                    variant=""
                    className="btn btn-outline-danger  btn-rounded position-relative me-5"
                    onClick={(e) => {
                      setFilter(currentFilter.replace(item, ""));
                    }}
                    style={{ margin: "0.5rem" }}
                  >
                    {
                      filterName === "startDate" || filterName === "endDate"
                        ? filterName
                            .replaceAll("startDate", "From")
                            .replaceAll("endDate", "To") +
                          " " +
                          new Date(
                            parseInt(item.split("=")[1])
                          ).toLocaleDateString()
                        : filterName.replaceAll("_", " ") +
                          " " +
                          item.split("=")[1]

                      /* item.replace("=", " : ")*/
                    }
                    <span className="position-absolute start-100 translate-middle badge rounded-pill bg-info">
                      x<span className="visually-hidden">delete filter</span>
                    </span>
                  </Button>
                ) : null;
              })
            : null}
        </div>
        <div className={"d-flex flex-row justify-content-start mb-3"}></div>
      </div>
      <table {...getTableProps()} className="table table-hover mb-0">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={column.className}
                >
                  <span className="tabletitle">{column.render("Header")}</span>
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-up"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            centered="true"
            id=""
          >
            <Modal.Header>
              <h6 className="modal-title">Details</h6>
              <Button
                variant=""
                type="button"
                onClick={() => setModalShow(false)}
              >
                <span aria-hidden="true" className="text-dark">
                  X
                </span>
              </Button>
              {actions}
            </Modal.Header>
            <Modal.Body>
              <ListGroup className="list-group">
                {currentRowId
                  ? Object.keys(currentRowId).map((key) => (
                      <ListGroupItem className="list-group-item" key={key}>
                        <div>
                          <strong>
                            {" "}
                            {key.replace("_", " ").toUpperCase()}{" "}
                          </strong>

                          {
                            //check if currentRowId[key] is object with keys
                            !String.isPrototypeOf(currentRowId[key]) ? (
                              currentRowId[key] !== undefined &&
                              currentRowId[key] !== null &&
                              (Object.keys(currentRowId[key]).includes(
                                "firstLevelReferral"
                              ) ||
                                Object.keys(currentRowId[key]).includes(
                                  "secondLevelReferral"
                                )) ? (
                                Object.keys(currentRowId[key]).map((key2) => (
                                  <ListGroupItem
                                    className="list-group-item"
                                    key={key2}
                                  >
                                    <strong>
                                      {" "}
                                      {key2
                                        .replace("_", " ")
                                        .toUpperCase()}{" "}
                                    </strong>
                                    <p>{currentRowId[key][key2]}</p>
                                  </ListGroupItem>
                                ))
                              ) : (
                                <div key={key}> {currentRowId[key]}</div>
                              )
                            ) : (
                              <div key={key}>{currentRowId[key]}</div>
                            )
                          }
                        </div>
                        {/* <p>{currentRowId[key].toString()}</p>*/}
                      </ListGroupItem>
                    ))
                  : ""}
              </ListGroup>
            </Modal.Body>
            <Modal.Footer className={"pd-12"}>
              <Button
                variant="secondary"
                className={""}
                onClick={() => setModalShow(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onDoubleClick={
                  (e) => {
                    setModalShow(true);
                    setCurrentRowId(data[row.id]);
                  }

                  //opn modal with details
                }
                onClick={(e) => {}}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="borderrigth"
                      {...cell.getCellProps()}
                      onClick={(e) => {
                        //copy to clipboard
                        /* Center();*/
                        navigator.clipboard.writeText(cell.value);

                        // show toast message "copied to clipboard"
                      }}
                    >
                      {
                        //check if cell.value === true or false
                        cell.value === true
                          ? "true"
                          : cell.value === false
                          ? "false"
                          : cell.render("Cell")
                      }
                      <ToastContainer />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1.5rem 0",
          }}
        >
          <select
            className=" mb-4 selectpage border me-1"
            value={size}
            onChange={(e) => {
              setSize(e.target.value);
              setPageSize(e.target.value);
            }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <Pagination className="">
            <Pagination.Item
              className={currentPage !== 0 ? "page-item" : "page-item disabled"}
              onClick={() =>
                currentPage !== 0 ? setPage(currentPage - 1) : null
              }
            >
              <i className="bi bi-chevron-left"></i>
            </Pagination.Item>

            {pageNumbersArray.map((number) => {
              return (
                <Pagination.Item
                  key={number}
                  onClick={() => setPage(number - 1)}
                  className={
                    currentPage + 1 === number
                      ? "page-item active"
                      : "page-item"
                  }
                >
                  {number}
                </Pagination.Item>
              );
            })}

            <Pagination.Item
              className={
                currentPage !== pageNumbersArray.length - 1
                  ? "page-item"
                  : "page-item disabled"
              }
              onClick={() =>
                currentPage !== pageNumbersArray.length
                  ? setPage(currentPage + 1)
                  : null
              }
            >
              <i className="bi bi-chevron-right"></i>
            </Pagination.Item>
          </Pagination>
        </div>
      </div>
    </>
  );
};
const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span className="d-flex ms-auto">
      <Form.Control
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        className="form-control mb-4"
        placeholder="Search..."
      />
    </span>
  );
};
