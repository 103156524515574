import fetcher from "../../utils/Fetcher";

export class User {
  id: string;
  email: string;
  password: string;
  companies: string[];
  roles: string[];
  provider: string;
  emailVerified: boolean;
  paymentMethodCode: string;
  firstName: string;
  lastName: string;
}
//getters and setters for User class
export async function getUser() {
  // @ts-ignore
  return fetcher("get", "/user", {}, {});
}
