import { getUser } from "../../../api/users/getUser.tsx";
import React, { useEffect, useState } from "react";
import AdminDashboard from "./AdminDashboard";
import SupportDashboard from "./SupportDashboard";
import { LargeLoader } from "../Loaders";
import Auth from "../../../Authentication/auth";

function DashboardSelector() {
  const [role, setRole] = useState("");
  useEffect(() => {
    getUser()
      .then((res) => {
        setRole(res.data.roles[0]);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          /* window.location.href = "/";*/
        }
      });
  }, []);

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      {role === "ROLE_ADMIN" ? <AdminDashboard /> : <Auth />}
    </React.Suspense>
  );
}
export default DashboardSelector;
