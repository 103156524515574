import {
  Card,
  Form,
  FormGroup,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import React, { useEffect } from "react";
import {
  createCode,
  deleteCode,
  getAllCodes,
  updateCode,
} from "../../../api/codes";
import Creatable from "react-select/creatable";

function CreateCode() {
  const [dateLimit, setDateLimit] = React.useState(false);
  const [code, setCode] = React.useState({
    code: "",
    size: "",
    type: "",
    userLimit: 0,
    totalLimit: 0,
    companies: [],
    dealTypes: [],
    startDate: 0,
    endDate: 0,
  });
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [codes, setCodes] = React.useState([]);
  const [toggleRefresh, setToggleRefresh] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [usage, setUsage] = React.useState();
  useEffect(() => {
    getAllCodes().then((res) => {
      setCodes(res.data);
    });
  }, [toggleRefresh]);

  function handleCreateCode() {
    createCode(code)
      .then((res) => {
        if (res.status === 200) {
          setToggleRefresh(!toggleRefresh);
          setSuccess("Code created successfully!");
        }
      })

      .catch((err) => {
        setError(err.response.data.message);
      });
  }

  function handleUpdateCode() {
    updateCode(code)
      .then((res) => {
        if (res.status === 200) {
          setToggleRefresh(!toggleRefresh);
          setSuccess("Code updated successfully!");
        }
      })

      .catch((err) => {
        setError(err.response.data.message);
      });
  }

  return (
    <Row>
      <Card className={"mg-t-20 wd-45p"}>
        <Card.Header className={"pd-b-0"}>
          <Card.Title>Create Promo Code</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form className="form-horizontal">
            <FormGroup className="form-group">
              <Form.Label className="control-label">Code</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                id="code"
                placeholder="Code"
                aria-required={true}
                required={true}
                value={code.code}
                onChange={(e) => setCode({ ...code, code: e.target.value })}
              />
            </FormGroup>
            <FormGroup className="form-group">
              <Form.Label className="control-label">Size</Form.Label>
              <div className="input-group">
                <Form.Control
                  type="text"
                  className="form-control"
                  id="amount"
                  placeholder="Amount"
                  aria-required={true}
                  required={true}
                  value={code.size}
                  onChange={(e) => setCode({ ...code, size: e.target.value })}
                />
                <Form.Select
                  className="form-control"
                  id="type"
                  aria-required={true}
                  required={true}
                  value={code.type}
                  onChange={(e) => setCode({ ...code, type: e.target.value })}
                >
                  <option value="">Of</option>
                  <option value={"%"}>%</option>
                  <option value={"USD"}>USD</option>
                  <option value={"GEL"}>GEL</option>
                  <option value={"USDT"}>USDT</option>
                </Form.Select>
              </div>
            </FormGroup>
            <FormGroup className="form-group">
              <Form.Label className="control-label">Date limit</Form.Label>
              <div
                onClick={() => setDateLimit(!dateLimit)}
                className={`main-toggle main-toggle-dark ${
                  dateLimit ? "on" : "off"
                }`}
              >
                <span></span>
              </div>
            </FormGroup>
            {dateLimit && (
              <div className={"input-group"}>
                <FormGroup className="form-group">
                  <Form.Label className="control-label">Start Date</Form.Label>
                  <InputGroup className="input-group reactdate-pic">
                    <InputGroup.Text className="input-group-text">
                      <i className="bi bi-calendar-heart"></i>
                    </InputGroup.Text>
                    <DatePicker
                      className="form-control"
                      selected={code.startDate}
                      onChange={(date) =>
                        setCode({ ...code, startDate: Date.parse(date) })
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="form-group ">
                  <Form.Label className="control-label">End Date</Form.Label>
                  <InputGroup className="input-group reactdate-pic">
                    <InputGroup.Text className="input-group-text">
                      <i className="bi bi-calendar-heart"></i>
                    </InputGroup.Text>
                    <DatePicker
                      className="form-control wd-100p"
                      selected={code.endDate}
                      onChange={(date) =>
                        setCode({ ...code, endDate: Date.parse(date) })
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            )}
            <FormGroup className={"form-group"}>
              <Form.Label className="control-label">User limit</Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                id="userLimit"
                placeholder="User limit"
                aria-required={true}
                value={code.userLimit}
                onChange={(e) =>
                  setCode({ ...code, userLimit: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup className={"form-group"}>
              <Form.Label className="control-label">Total limit</Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                id="totalLimit"
                placeholder="Total limit"
                aria-required={true}
                value={code.totalLimit}
                onChange={(e) =>
                  setCode({ ...code, totalLimit: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup className={"form-group"}>
              <Form.Label className="control-label">Companies</Form.Label>
              {/*<MultiSelect
                id="select-beasts"
                name="beast"
                placeholder="Select companies"
                options={[
                  { value: "CASH_TBILISI", label: "Cash Tbilisi" },
                  { value: "CASH_BATUMI", label: "Cash Batumi" },
                  { value: "visa_mastercard", label: "Visa/Mastercard" },
                ]}
                onChange={(e) => {
                  let companies = [];
                  e.split(",").map((item) => {
                    companies.push(item);
                  });

                  setCode({ ...code, companies: companies });
                }}
                value={code.companies}
              />*/}
              <Creatable
                classNamePrefix="background"
                display="value"
                options={[
                  {
                    label: "Companies",
                    options: [
                      { value: "cashTbilisi", label: "Cash Tbilisi" },
                      { value: "cashBelgrade", label: "Cash Belgrade" },
                    ],
                  },
                ]}
                value={
                  code.companies &&
                  code.companies.map((item) => {
                    return { value: item, label: item, selected: "true" };
                  })
                }
                onChange={(e) => {
                  let companies = [];
                  e.map((item) => {
                    companies.push(item.value);
                  });

                  setCode({ ...code, companies: companies });
                }}
                labelledBy="Select"
                isMulti
              />
            </FormGroup>
            <FormGroup className={"form-group"}>
              <Form.Label className="control-label">Deal types</Form.Label>
              <Creatable
                classNamePrefix="background"
                display="value"
                options={[
                  {
                    label: "Deal types",
                    options: [
                      { value: "buy", label: "Buy" },
                      { value: "sell", label: "Sell" },
                    ],
                  },
                ]}
                value={
                  code.dealTypes &&
                  code.dealTypes.map((item) => {
                    return { value: item, label: item, selected: "true" };
                  })
                }
                onChange={(e) => {
                  let dealTypes = [];
                  e.map((item) => {
                    dealTypes.push(item.value);
                  });

                  setCode({ ...code, dealTypes: dealTypes });
                }}
                labelledBy="Select"
                isMulti
              />
            </FormGroup>
          </Form>
        </Card.Body>
        <Card.Footer>
          {success && <p className={"text-success"}>{success}</p>}
          {error && <p className={"text-danger"}>{error}</p>}
          <button
            className="btn btn-primary"
            onClick={() => {
              setSuccess("");
              setError("");
              if (edit) {
                handleUpdateCode();
              } else {
                handleCreateCode();
              }
            }}
          >
            {edit ? "Update" : "Create"}
          </button>
        </Card.Footer>
      </Card>
      <Card className={"mg-t-20 mg-l-20"}>
        <Card.Header className={"pd-b-0"}>
          <Card.Title>All codes</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Size</th>
                <th>Status</th>
                <th>Used</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {codes.map((item) => (
                <tr key={item._id}>
                  <td>{item.code}</td>
                  <td>
                    {item.size} {item.type}
                  </td>
                  <td>{item.status}</td>
                  <td>{item.usages.length}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setCode(item);
                        item.startDate
                          ? setDateLimit(true)
                          : setDateLimit(false);
                        setEdit(true);
                      }}
                    >
                      <i className={"bi bi-pencil"}></i>
                    </button>
                    <button
                      className="btn btn-danger mg-l-4"
                      onClick={() => {
                        deleteCode(item.code).then((res) => {
                          if (res.status === 200) {
                            setToggleRefresh(!toggleRefresh);
                          }
                        });
                      }}
                    >
                      <i className={"bi bi-trash"}></i>
                    </button>
                    <button
                      className={"btn btn-info mg-l-4"}
                      onClick={() => {
                        setUsage(item?.usages);
                        setModal(true);
                      }}
                    >
                      <i className={"bi bi-eye"}></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={modal}>
        <Modal.Header>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {usage ? (
            usage?.map((item) => {
              return (
                <div>
                  <span>User id: {item.userId}</span>
                  <br />
                  <span>Deal : {item.dealNumber}</span>
                  <br />
                  <span>Company : {item.paymentMethodCode}</span>
                  <br />
                  <span>Deal type : {item.dealType}</span>
                  <br />
                  <span>Used at: {new Date(item.usedAt).toLocaleString()}</span>
                  <br />
                  <span>Crypto code: {item.cryptoCode}</span>
                  <br />
                  <span>Crypto value: {item.cryptoValue}</span>
                  <br />
                  <span>Fiat code: {item.fiatCode}</span>
                  <br />
                  <span>Fiat value: {item.fiatValue}</span>
                  <br />
                  <span>Service fee: {item.serviceFee}</span>
                  <br />
                  <br />
                </div>
              );
            })
          ) : (
            <p>No usage yet</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setModal(false);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
}

export default CreateCode;
