import CookiesStore from "../../utils/CookiesStore";
import axios from "axios";
import { API_URL } from "../Constants";
import fetcher from "../../utils/Fetcher";

export class Reward {
  requestNumber!: string;
  createdAt!: string;
  completedAt!: string;
  totalAmount!: string;
  wallet!: string;
  status!: string;
}

export class RewardFilters {}

export async function getRewards(size, page, search, filter, sort) {
  return await fetcher(
    "get",
    search
      ? "/referrals/rewards/search?value=" + search
      : "/referrals/rewards?sort=totalRewards," +
          sort +
          "&size=" +
          size +
          "&page=" +
          page +
          filter
  );
}

export async function getFilters() {
  const jwtToken = CookiesStore.getJwtToken();
  try {
    const response = await axios({
      method: "get",
      url: API_URL + "/filters/REWARDS",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
