import fetcher from "../../utils/Fetcher";

export default async function recordStat(company, dateFrom, dateTo, step) {
  return await fetcher(
    "get",
    "/reports/" +
      company +
      "?dateFrom=" +
      dateFrom +
      "&dateTo=" +
      dateTo +
      "&step=" +
      step,
    {}
  );
}
export function clientsStat(dateFrom, dateTo, step) {
  return fetcher(
    "get",
    "/reports/clients/" +
      "?dateFrom=" +
      dateFrom +
      "&dateTo=" +
      dateTo +
      "&step=" +
      step,
    {}
  );
}
