import fetcher from "../../utils/Fetcher";

export class Safe {
  currencies: { [key: string]: Currency };
  totalNet: number;
}

export class Currency {
  total: number;
  pending: number;
  net: null;
}

export async function getSafe(company: string) {
  // @ts-ignore
  return await fetcher("get", "/companies/" + company + "/safes", {}, {});
}
export async function getAllSafes() {
  // @ts-ignore
  return await fetcher("get", "/companies/safes/balance", {}, {});
}
export async function addToSafe(
  company: string,
  currency: string,
  amount: number
) {
  // @ts-ignore
  return await fetcher(
    "post",
    "/companies/safes/" + company + "/add",
    { amount: amount, currencyCode: currency },
    {}
  );
}
export async function subtractFromSafe(
  company: string,
  currency: string,
  amount: number
) {
  // @ts-ignore
  return await fetcher(
    "post",
    "/companies/safes/" + company + "/subtract",
    { amount: amount, currencyCode: currency },
    {}
  );
}
