import fetcher from "../../utils/Fetcher";

export function createCode(code) {
  return fetcher("POST", "/code/create", code);
}
export function getAllCodes() {
  return fetcher("GET", "/code/all");
}
export function deleteCode(code) {
  return fetcher("DELETE", "/code/delete/" + code);
}
export function updateCode(code) {
  return fetcher("PUT", "/code/update", code);
}
