import { Card, Col, InputGroup, Row } from "react-bootstrap";
import DateSelector from "../../DateSelector";
import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import getStat from "../../../api/companies/getStat";

function DealsValues({ company }) {
  const [startDate, setStartDate] = useState(() => {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth());
    return currentDate.getTime();
  });
  const [endDate, setEndDate] = useState(new Date());
  const [dealData, setDealData] = useState([]);
  const [step, setStep] = useState(1);
  const [totalUSDTBought, setTotalUSDTBought] = useState(0);
  const [totalUSDTSold, setTotalUSDTSold] = useState(0);
  const [totalUSDSpent, setTotalUSDSpent] = useState(0);
  const [totalUSDReceived, setTotalUSDReceived] = useState(0);

  const [totalNetSell, setTotalNetSell] = useState(0);
  const [totalNetBuy, setTotalNetBuy] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalSalary, setTotalSalary] = useState(0);
  const [totalReward, setTotalReward] = useState(0);

  const [totalBuyCount, setTotalBuyCount] = useState(0);
  const [totalSellCount, setTotalSellCount] = useState(0);

  useEffect(() => {
    getStat(
      company,
      new Date(startDate).toISOString().substring(0, 10),
      new Date(endDate).toISOString().substring(0, 10),
      step
    ).then(
      (res) => {
        setDealData(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [step, startDate, endDate]);

  useEffect(() => {
    setTotalUSDTBought(
      dealData.reduce((acc, cur) => acc + cur.totalUSDTReceived, 0)
    );
    setTotalUSDTSold(dealData.reduce((acc, cur) => acc + cur.totalUSDTSent, 0));
    setTotalUSDSpent(dealData.reduce((acc, cur) => acc + cur.totalCashOut, 0));
    setTotalUSDReceived(
      dealData.reduce((acc, cur) => acc + cur.totalCashReceived, 0)
    );
    setTotalNetSell(dealData.reduce((acc, cur) => acc + cur.netFromSell, 0));
    setTotalNetBuy(dealData.reduce((acc, cur) => acc + cur.netFromBuy, 0));
    setTotalNet(dealData.reduce((acc, cur) => acc + cur.totalNet, 0));
    setTotalCost(dealData.reduce((acc, cur) => acc + cur.costSum, 0));
    setTotalSalary(dealData.reduce((acc, cur) => acc + cur.salarySum, 0));
    setTotalReward(dealData.reduce((acc, cur) => acc + cur.rewardSum, 0));
    setTotalBuyCount(dealData.reduce((acc, cur) => acc + cur.buyDealsCount, 0));
    setTotalSellCount(
      dealData.reduce((acc, cur) => acc + cur.sellDealsCount, 0)
    );
  }, [dealData]);

  const chartDataValues = {
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      xaxis: {
        categories: dealData?.map((d, i) =>
          step === 1
            ? new Date(d.startDate).toLocaleDateString().substring(0, 5)
            : new Date(d.startDate).toLocaleDateString().substring(0, 5) +
              "-" +
              new Date(d.endDate).toLocaleDateString().substring(0, 5)
        ),
      },
      yaxis: {
        title: {
          text: "Values",
        },
        min: 0,
      },
      legend: {
        position: "bottom",
      },
    },
    series: [
      {
        name: "Buys Value Sent",
        data: dealData.map((d) => d.totalUSDTSent),
        color: "#38cab3",
      },

      {
        name: "Buys Value Cash Received",
        data: dealData.map((d) => d.totalCashReceived),
        color: "#57c489",
      },

      {
        name: "Sells Value Cash Out",
        data: dealData.map((d) => d.totalCashOut),
        color: "#f74f75",
      },
      {
        name: "Sells Value USDT Received",
        data: dealData.map((d) => d.totalUSDTReceived),
        color: "#f74f4f",
      },
      {
        name: "All Value",
        data: dealData.map((d) => d.totalUSDTReceived + d.totalCashReceived),
        color: "#4ec2f0",
      },
    ],
  };

  const chartDataNetAndCost = {
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      xaxis: {
        categories: dealData?.map((d, i) =>
          step === 1
            ? new Date(d.startDate).toLocaleDateString().substring(0, 5)
            : new Date(d.startDate).toLocaleDateString().substring(0, 5) +
              "-" +
              new Date(d.endDate).toLocaleDateString().substring(0, 5)
        ),
      },
      yaxis: {
        title: {
          text: "Counts",
        },
        min: 0,
      },
      legend: {
        position: "bottom",
      },
    },
    series: [
      {
        name: "Buy Net Value",
        data: dealData.map((d) => d.netFromBuy),
        color: "#38cab3",
      },

      {
        name: "Sell Net Value",
        data: dealData.map((d) => d.netFromSell),
        color: "#f74f75",
      },
      {
        name: "Total Net Value",
        data: dealData.map((d) => d.totalNet),
        color: "#4ec2f0",
      },

      {
        name: "Cost Value",
        data: dealData.map((d) => d.costSum),
        color: "#be0022",
      },
      {
        name: "Salary Value",
        data: dealData.map((d) => d.salarySum),
        color: "#0a6000",
      },
      {
        name: "Rewards Value",
        data: dealData.map((d) => d.rewardSum),
        color: "#00658d",
      },
    ],
  };

  const chartDataCounts = {
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      xaxis: {
        categories: dealData?.map((d, i) =>
          step === 1
            ? new Date(d.startDate).toLocaleDateString().substring(0, 5)
            : new Date(d.startDate).toLocaleDateString().substring(0, 5) +
              "-" +
              new Date(d.endDate).toLocaleDateString().substring(0, 5)
        ),
      },
      yaxis: {
        title: {
          text: "Counts",
        },
        min: 0,
      },
      legend: {
        position: "bottom",
      },
    },
    series: [
      {
        name: "Buys Count",
        data: dealData.map((d) => d.buyDealsCount),
        color: "#38cab3",
      },

      {
        name: "Sells Count",
        data: dealData.map((d) => d.sellDealsCount),
        color: "#f74f75",
      },
      {
        name: "All Count",
        data: dealData.map((d) => d.buyDealsCount + d.sellDealsCount),
        color: "#4ec2f0",
      },
    ],
  };

  return (
    <Card className={"mg-t-20"}>
      <Card.Header
        className=" border-bottom-0 d-flex"
        style={{ justifyContent: "space-between" }}
      >
        <h3 className="card-title mb-2">Deals values</h3>
      </Card.Header>
      <Card.Body>
        <Row className={"justify-content-between"}>
          <Col>
            <Row>
              <Col xl={3} lg={4} md={5} xs={100} className="">
                From
                <InputGroup className="input-group reactdate-pic">
                  <InputGroup.Text className="input-group-text">
                    <i className="bi bi-calendar-heart"></i>
                  </InputGroup.Text>
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </InputGroup>
              </Col>
              <Col xl={3} lg={4} md={5} xs={100} className="">
                To
                <InputGroup className="input-group reactdate-pic">
                  <InputGroup.Text className="input-group-text">
                    <i className="bi bi-calendar-heart"></i>
                  </InputGroup.Text>
                  <DatePicker
                    className="form-control"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
          <DateSelector step={step} setStep={setStep} />
        </Row>
        <ReactApexChart
          className="mt-4"
          options={chartDataValues.options}
          series={chartDataValues.series}
          type="bar"
          height={350}
        />
      </Card.Body>
      <Card.Footer>
        <Col>
          <Row>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total USDT bought</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalUSDTBought}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-secondary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total USD spent</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalUSDSpent}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-secondary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>

            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total USDT sold</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalUSDTSold}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-primary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total USD received</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalUSDReceived}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-primary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Card.Footer>
      <Card.Header
        className="d-flex bd-t"
        style={{ justifyContent: "space-between" }}
      >
        <h3 className="card-title mb-2">Profit & Costs</h3>
      </Card.Header>
      <ReactApexChart
        options={chartDataNetAndCost.options}
        series={chartDataNetAndCost.series}
        type="bar"
        height={350}
      />

      <Card.Footer>
        <Col>
          <Row>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">NET from Sell</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalNetSell}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-secondary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">NET from Buy</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalNetBuy}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-primary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">SELL NET + BUY NET</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalNet}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-info-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-info"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>

            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Clear NET</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalNet - totalCost - totalSalary - totalReward}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-info-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-info"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total costs</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalCost}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-secondary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total salary</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalSalary}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-secondary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total rewards</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalReward}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-secondary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Card.Footer>

      <Card.Header
        className="d-flex bd-t"
        style={{ justifyContent: "space-between" }}
      >
        <h3 className="card-title mb-2">Deals counts</h3>
      </Card.Header>
      <ReactApexChart
        options={chartDataCounts.options}
        series={chartDataCounts.series}
        type="bar"
        height={350}
      />

      <Card.Footer>
        <Col>
          <Row>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total BUY deals</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalBuyCount}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-primary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total SELL deals</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalSellCount}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-secondary"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
            <Col xl={3} lg={5} md={7} xs={9}>
              <Card className="sales-card bd bd radius-5">
                <Row>
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total deals</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            {totalBuyCount + totalSellCount}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">
                          Growth
                          <i className="fa fa-caret-up mx-2 text-success"></i>
                          <span className="text-success font-weight-semibold">
                            ---%
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-info-transparent text-center align-self-center overflow-hidden">
                      <i className="bi bi-asterisk tx-18 text-info"></i>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Card.Footer>
    </Card>
  );
}

export default DealsValues;
