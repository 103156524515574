import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  getConfig,
  updatePaymentMethodsConfig,
} from "../../../api/config/config";
import { LargeLoader } from "../../Dashboard/Loaders";
import TreeView from "../EmptyPage/TreeView";

//asd
function Config({ name }) {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);

    getConfig(name)
      .then((res) => {
        console.log(res);
        setSettings(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, [name]);

  async function updateCompany(company, data) {
    await updatePaymentMethodsConfig(company, data).then((response) => {
      if (response.status === 200) {
        getConfig(name).then((res) => {
          setSettings(res.data);
        });
      }
    });
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span
            className="main-content-title mg-b-0 mg-b-lg-1"
            style={{
              textTransform: "uppercase",
            }}
          >
            {name?.replace("-", " ")} CONFIG
          </span>
        </div>
      </div>
      {/* <!-- /breadcrumb -->

					<!-- row --> */}
      <Row>
        <Col md={12} xl={12} xs={12} sm={12}>
          <Card>
            <Card.Header className={"d-flex flex-row justify-content-between"}>
              <Card.Title as="h6">
                {name?.replace("-", " ")} configuration
              </Card.Title>
              <Button variant="primary" className="btn-sm">
                Add new
              </Button>
            </Card.Header>
            <Card.Body>
              <Row>
                {loading ? <LargeLoader /> : null}
                {error?.message ? (
                  <div className="alert alert-danger" role="alert">
                    {error.message}
                  </div>
                ) : null}
                {settings.map((company, index) => {
                  return (
                    <Col
                      md={4}
                      xl={4}
                      xs={4}
                      sm={4}
                      key={index}
                      className={"mg-r-10 mg-b-10 bd bd-2"}
                    >
                      <Card.Header
                        className={"d-flex flex-row justify-content-between"}
                      >
                        <Card.Title as="h6">{company.name} </Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <TreeView
                          data={company}
                          key={index}
                          defaultExpanded={false}
                          editableFields={["sellFee", "buyFee", "isActive"]}
                          handleSave={updateCompany}
                          type={name}
                        />
                      </Card.Body>
                    </Col>
                  );
                })}
                {/*{settings &&
                  settings.map((item, index) => {
                    return (
                      <Col
                        md={4}
                        xl={4}
                        xs={4}
                        sm={4}
                        key={index}
                        className={"mg-r-10 mg-b-10 bd bd-2"}
                      >
                        <Card.Header
                          className={"d-flex flex-row justify-content-between"}
                        >
                          <Card.Title as="h6">{item.name} </Card.Title>
                          <Button variant="primary" className="btn-sm">
                            Edit
                          </Button>
                        </Card.Header>
                        <Card.Body>
                          {Object.keys(item)
                            .sort()
                            .map((key, index) => {
                              return (
                                <div key={index}>
                                  <div
                                    className={
                                      "d-flex flex-row justify-content-between "
                                    }
                                  >
                                    <h6>{key}</h6>

                                    <div>
                                      {key === "safe"
                                        ? Object.keys(item[key]).map(
                                            (curr, index) => {
                                              return (
                                                <Row key={index}>
                                                  <h6>{curr}</h6>
                                                  <p>{item[key][curr]}</p>
                                                </Row>
                                              );
                                            }
                                          )
                                        : key === "active"
                                        ? item[key]
                                          ? "Active"
                                          : "Inactive"
                                        : item[key]}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}{" "}
                        </Card.Body>
                      </Col>
                    );
                    /*<div key={index}>
                      <h6>{item.name}</h6>
                      <p>Code : {item.paymentCode}</p>
                      <p>Status : {item.active ? "Enabled" : "Disabled"}</p>
                      <p>Buy fee : {item.buyFee}</p>
                      <p>Sell fee : {item.sellFee}</p>
                      <p>Priority : {item.priority}</p>
                    </div>*/}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <!-- row closed --> */}
    </div>
  );
}

Config.propTypes = {};

Config.defaultProps = {};

export default Config;
