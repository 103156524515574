import React, { useEffect, useState } from "react";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { BasicTable } from "../../Tables/DataTables/Basictable";
import { getRewards, getFilters, Reward } from "../../../api/users/getRewards";

function Rewards() {
  const [rewards, setRewards] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [sort, setSort] = React.useState("desc");

  useEffect(() => {
    getFilters().then((response) => {});
  }, []);

  useEffect(() => {
    getRewards(pageSize, currentPage, search, filter, sort).then((response) => {
      let rewardsArray = [];
      response.data.content.forEach((el) => {
        let reward = new Reward();
        reward.requestNumber = el.requestNumber;
        reward.createdAt = new Date(el.createdAt).toLocaleString();
        reward.status = el.status;
        reward.totalAmount = el.totalAmount;
        reward.completedAt = new Date(el.completedAt).toLocaleString();
        reward.wallet = el.wallet;
        rewardsArray.push(reward);
      });
      if (rewards.length === 0) {
        // setDeals(response.content);

        setRewards(rewardsArray);
        setTotalElements(response.data.totalElements);
        setTotalPages(response.data.totalPages);
      } else if (rewards.length > 0) {
        setTotalElements(response.data.totalElements);
        setTotalPages(response.data.totalPages);
        return setRewards(rewardsArray);
        /*  setDeals(deals.concat(response.content));
                      setTotalElements(response.totalElements);
                      setTotalPages(response.totalPages);*/
      } else {
      }
    });
  }, [
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    search,
    setSearch,
    filter,
    setFilter,
    sort,
    setSort,
  ]);

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Referrals : {totalElements}
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" href="#">
              Transactions
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumb-item "
              active
              aria-current="page"
            >
              Users
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb -->

					<!-- row --> */}
      <Row>
        <Col md={12} xl={12} xs={12} sm={12}>
          <Card>
            <Card.Body>
              <div>
                {/*  <Savetable data={users} headers={["id", "type", "status", "userNumber", "paymentMethodCode"]}/>*/}
                <BasicTable
                  data={rewards}
                  headers={[
                    {
                      Header: "requestNumber",
                      accessor: "requestNumber",
                      className: "borderrigth",
                    },
                    {
                      Header: "createdAt",
                      accessor: "createdAt",
                      className: "borderrigth",
                    },
                    /* {
                                              Header: "referralCode",
                                              accessor: "referralCode",
                                              className: "borderrigth",
                                            },*/
                    /*{
                                              Header: "percentages",
                                              accessor: "percentages",
                                              className: "borderrigth",
                                            },*/

                    {
                      Header: "completedAt",
                      accessor: "completedAt",
                      className: "borderrigth",
                    },
                    {
                      Header: "totalAmount",
                      accessor: "totalAmount",
                      className: "borderrigth",
                    },
                    {
                      Header: "wallet",
                      accessor: "wallet",
                      className: "borderrigth",
                    },
                    {
                      Header: "status",
                      accessor: "status",
                      className: "borderrigth",
                    },
                  ]}
                  size={pageSize}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setPage={setCurrentPage}
                  setSize={setPageSize}
                  setSearch={setSearch}
                  filters={filters}
                  currentFilter={filter}
                  setFilter={setFilter}
                  sort={sort}
                  setSort={setSort}
                  searchOptions={"User ID"}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <!-- row closed --> */}
    </div>
  );
}

Rewards.propTypes = {};

Rewards.defaultProps = {};

export default Rewards;
