import fetcher from "../../utils/Fetcher";

export default async function recordCost(data, company) {
  return await fetcher(
    "post",
    "/companies/" + company + "/safes/addCost",
    data,
    {}
  );
}
export async function recordSalary(
  userId,
  outcomeAmount,
  outcomeCurrency,
  details,
  company
) {
  return await fetcher(
    "post",
    "/companies/safes/" + company + "/salary",
    {
      userId,
      outcomeAmount,
      outcomeCurrency,
      details,
    },
    {}
  );
}
export async function recordDividends(
  userId,
  outcomeAmount,
  outcomeCurrency,
  details,
  company
) {
  return await fetcher(
    "post",
    "/companies/safes/" + company + "/dividends",
    {
      userId,
      outcomeAmount,
      outcomeCurrency,
      details,
    },
    {}
  );
}
