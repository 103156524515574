import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
} from "react-bootstrap";

import React, { useEffect } from "react";
import DealsStat from "./DealsStat";
import UsersStat from "./UsersStat";
import { addToSafe, subtractFromSafe } from "../../../api/companies/getSafe";
import { SmallLoader } from "../Loaders";

function SafeCardAdmin({ company, currency, total, pending, net, totalNet }) {
  const [percentage, setPercentage] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const [modalAction, setModalAction] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  useEffect(() => {
    setPercentage(Math.round((net / total) * 100));
  }, [net, total]);

  let color = "primary";
  // set red color for 0-15 and green for 85-100
  if (percentage < 15) {
    color = "secondary";
  } else if (percentage < 35) {
    color = "warning";
  } else {
    color = "success";
  }
  const handleCloseModal = () => {
    setShowModal(false);
    setAmount("");
  };

  const handleOpenModal = (action) => {
    setModalAction(action);
    setShowModal(true);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    if (amount <= 0) {
      setLoading(false);
      setError("Amount must be greater than 0");
      return;
    }
    if (modalAction === "subtract") {
      subtractFromSafe(company, currency, amount)
        .then((r) => {
          console.log(r);
          setLoading(false);
          handleCloseModal();
          window.location.reload();
        })
        .catch((e) => {
          setError(e.message);
          setLoading(false);
        });
    } else if (modalAction === "add") {
      addToSafe(company, currency, amount)
        .then((r) => {
          console.log(r);
          setLoading(false);
          handleCloseModal();
          window.location.reload();
        })
        .catch((e) => {
          setError(e.message);
          setLoading(false);
        });
    }
  };

  return (
    <div className={"wd-100p px-4 bd bd-y-1 pd-t-20"}>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>
            How much {currency} you want to {modalAction} to the safe?
          </Modal.Title>
          <Button
            variant="link"
            onClick={handleCloseModal}
            className={"btn-xl r-0 t-0  mr-2"}
            style={{ fontSize: "1.5rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form className={"d-flex"}>
            <Form.Control
              type="number"
              className={"mg-r-10"}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />{" "}
            <Button variant="primary" onClick={handleSubmit}>
              {loading ? <SmallLoader /> : "Submit"}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {error ? <p className={"text-danger"}>{error}</p> : null}
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <div
          className="d-flex align-items-center mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <h6 className="tx-semibold tx-18 ">{currency}</h6>
          <div>
            <Button
              variant="outline-primary"
              size="sm"
              className={"mg-r-10"}
              onClick={() => handleOpenModal("add")}
            >
              <i className="bi bi-plus"></i>
            </Button>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => handleOpenModal("subtract")}
            >
              <i className="bi bi-dash"></i>
            </Button>
          </div>
        </div>
      </div>
      {/*  <div className="col-3 d-flex align-items-center justify-content-center">

        </div>*/}
      <div className="">
        <Row>
          <Col className={"d-flex flex-column justify-content-between"}>
            <Row className={"d-flex flex-row justify-content-between  "}>
              <h6 className="mb-2 text-muted">TOTAL</h6>

              <h4 className="tx-16 font-weight-semibold mb-0">{total}</h4>
            </Row>
            <Row className={"d-flex flex-row justify-content-between  "}>
              <h6 className="mb-2 text-muted">PENDING</h6>

              <h4 className="tx-16 font-weight-semibold mb-0">{pending}</h4>
            </Row>
            <Row className={"d-flex flex-row justify-content-between  "}>
              <h6 className="mb-4 text-muted">NET</h6>
              <h4 className="tx-16 font-weight-semibold mb-0">{net}</h4>
            </Row>
          </Col>
          {/* <Col

                className="d-flex align-items-center justify-content-center upgrade-chart-circle"
            >
              <div className="chart-circle float-md-end mt-0 mt-md-0 mb-4 pd-0 wd-100p">
                <ReactApexChart
                    options={Radialbar.options}
                    series={Radialbar.series}
                    type="radialBar"
                    height={180}
                />
                <div className="chart-circle-value circle-style">
                  <div className="tx-18 font-weight-semibold">
                    {percentage}%
                  </div>
                </div>
              </div>
            </Col>
*/}
        </Row>
      </div>
      <div className="mb-3">
        <div className="progress progress-sm h-1 mb-1">
          <div
            style={{ width: percentage + "%" }}
            className={"progress-bar bg-" + color + " wd-" + percentage + "p"}
            role="progressbar"
          ></div>
        </div>
        <small className="mb-0 text-muted">
          Free<span className="float-end text-muted">{percentage}%</span>
        </small>
      </div>
    </div>
  );
}

function Statistic({ company, safe, totalNet }) {
  console.log(safe);
  useEffect(() => {}, []);
  //asd
  return (
    <div
      className="panel-body tabs-menu-body main-content-body-right"
      id="tab4"
    >
      <Col>
        <Card className=" sales-card">
          <Row>
            <div className="col-8">
              <div className="ps-4 pt-4 pe-3 pb-4">
                <div className="">
                  <h6 className="mb-2 tx-12 ">Total net worth</h6>
                </div>
                <div className="pb-0 mt-0">
                  <div className="d-flex">
                    <h4 className="tx-20 font-weight-semibold mb-2">
                      {totalNet}
                    </h4>
                  </div>
                  <p className="mb-0 tx-12 text-muted">
                    Last week
                    <i className="fa fa-caret-up mx-2 text-success"></i>
                    <span className="text-success font-weight-semibold">
                      ---
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                <i className="bi bi-cash-coin tx-20 text-primary"></i>
              </div>
            </div>
          </Row>
        </Card>
        <Row>
          {Object.keys(safe).map((key, index) => {
            return (
              <Col xl={4} key={index}>
                <SafeCardAdmin
                  company={company}
                  currency={key}
                  total={safe[key].total}
                  pending={safe[key].pending}
                  net={safe[key].net}
                  totalNet={totalNet}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
      <Row>
        <Col>
          <DealsStat company={company} />
        </Col>
      </Row>
    </div>
  );
}

export default Statistic;
