import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DealsValues from "./DealsValues";
import DealsCounts from "./DealsCounts";
import getStat from "../../../api/companies/getStat";

function DealsStat({ company }) {
  return (
    <div>
      <DealsValues company={company} />
      {/*<DealsCounts />*/}
    </div>
  );
}

export default DealsStat;
export const Datepicker = () => {
  const [date, setDate] = useState(new Date());
  return (
    <DatePicker
      className="form-control"
      selected={date}
      onChange={(date) => setDate(date)}
    />
  );
};
