import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Modal,
  OverlayTrigger,
  Pagination,
  Popover,
  Table,
} from "react-bootstrap";
import TreeView from "./TreeView";
import CalendarFilter from "../../Calendar/CalendarFilter";
import SalaryModal from "../../Dashboard/Modals/SalaryModal";

const ObjectTable = ({
  data,
  columnNames,
  editableFields,
  update,
  totalPages,
  currentPage,
  size,
  setPage,
  setSize,
  setSearch,
  filters,
  currentFilter,
  setFilter,
  sort,
  setSort,
  searchOptions,
  actions,
  type,
  role,
  disableSearch,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editableValues, setEditableValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [salaryModal, setSalaryModal] = useState(false);
  const [from, setFrom] = React.useState("");
  const [openFrom, setOpenFrom] = React.useState(false);
  const [to, setTo] = React.useState("");
  const [openTo, setOpenTo] = React.useState(false);
  const pageNumbersArray = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbersArray.push(i);
  }

  function openCalendar(e, type) {
    if (type === "from") {
      setOpenFrom(!openFrom);
    } else {
      setOpenTo(!openTo);
    }
  }

  useEffect(() => {
    if (from) {
      setFilter(currentFilter + "&startDate=" + new Date(from).getTime());
    }
    if (to) {
      setFilter(currentFilter + "&endDate=" + new Date(to).getTime());
    }
  }, [from, to]);

  const handleCloseModal = () => {
    setShowModal(false);
    if (editMode) {
      setEditMode(!editMode);
    }
  };
  const handleShowModal = (obj) => {
    setSelectedObject(obj);
    setShowModal(true);
  };

  const handleEditField = () => setEditMode(!editMode);

  const handleSave = () => {
    setLoading(true);
    const payload = {};
    editableFields.forEach((field) => {
      payload[field] = editableValues[field]
        ? editableValues[field]
        : selectedObject[field];
    });
    update(selectedObject.id, payload)
      .then((res) => {
        setEditMode(false);
        setLoading(false);
        setSelectedObject({ ...selectedObject, ...payload });
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  const handleInputChange = (e, field) => {
    setEditableValues({ ...editableValues, [field]: e.target.value });
  };

  function handleSalaryModal(object) {
    setSalaryModal(!salaryModal);
    setSelectedObject(object);
  }

  return (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: "column",
        }}
      >
        <div>
          <input
            placeholder={
              to
                ? "From " + from + " To " + to
                : from
                ? "From " + from
                : "From - To"
            }
            className={"form-control mb-3 wd-xs-300"}
            value={
              to
                ? "From " + from + " To " + to
                : from
                ? "From " + from
                : "From - To"
            }
            onClick={(e) => {
              setOpenFrom(!openFrom);
            }}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
          />
          <CalendarFilter
            open={openFrom}
            setOpen={setOpenFrom}
            setStartDate={setFrom}
            setEndDate={setTo}
            startDate={from}
            endDate={to}
          />
        </div>
        {disableSearch === true ? null : (
          <div className="">
            <input
              type="text"
              className={"form-control wd-xs-700"}
              placeholder={"Search by " + searchOptions}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="input-group-append"></span>
          </div>
        )}

        <ButtonGroup className="ms-2 mt-2 mb-2" style={{ marginLeft: "0" }}>
          {filters
            ? Object.keys(filters).map((filter, index) => {
                return (
                  <Dropdown key={index}>
                    <Dropdown.Toggle
                      variant=""
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="btn ripple btn-rounded btn-outline-primary"
                      data-bs-toggle="dropdown"
                      id="dropdownMenuButton12"
                      type="button"
                      style={{
                        marginBottom: "0.5rem",
                        marginRight: "0.5rem",
                        textTransform: "capitalize",
                      }}
                    >
                      {filter.replaceAll("_", " ")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{ margin: "0px" }}
                      className="dropdown-menu tx-13"
                    >
                      {filters[filter].map((item, index) => {
                        return (
                          <Dropdown.Item
                            className="dropdown-item"
                            href="#"
                            key={index}
                            onClick={(e) => {
                              console.log("changed");
                              console.log(filter);
                              console.log(item);
                              if (currentFilter.length !== 0) {
                                setFilter(
                                  currentFilter +
                                    "&" +
                                    filter.replaceAll("_", "") +
                                    "=" +
                                    item
                                );
                              } else {
                                setFilter(
                                  currentFilter +
                                    "&" +
                                    filter.replaceAll("_", "") +
                                    "=" +
                                    item
                                );
                              }
                            }}
                          >
                            {item}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              })
            : null}

          <div>
            {currentFilter?.length > 0 ? (
              <Button
                variant=""
                className="btn me-2 btn-secondary"
                onClick={(e) => {
                  setFilter("");
                }}
              >
                Clear Filter
              </Button>
            ) : null}
          </div>

          <div
            className="ms-auto"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={(e) => {
              setSort(sort === "asc" ? "desc" : "asc");
            }}
          >
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={
                <Popover style={{ margin: "0px" }}>
                  <Popover.Header as="h3">
                    Sort {sort === "asc" ? "Ascending" : "Descending"}
                  </Popover.Header>
                </Popover>
              }
            >
              <Button variant="" className=" btn btn-primary">
                <i
                  className={
                    sort === "desc" ? "bi bi-sort-down" : "bi bi-sort-up"
                  }
                ></i>
              </Button>
            </OverlayTrigger>
          </div>
        </ButtonGroup>
        <div className="d-flex flex-row justify-content-start">
          {currentFilter
            ? currentFilter.split("&").map((item, index) => {
                console.log(item);
                let filterName = item.split("=")[0];
                return item ? (
                  <Button
                    variant=""
                    className="btn btn-outline-danger  btn-rounded position-relative me-5"
                    onClick={(e) => {
                      setFilter(currentFilter.replace(item, ""));
                    }}
                    style={{ margin: "0.5rem" }}
                  >
                    {
                      filterName === "startDate" || filterName === "endDate"
                        ? filterName
                            .replaceAll("startDate", "From")
                            .replaceAll("endDate", "To") +
                          " " +
                          new Date(
                            parseInt(item.split("=")[1])
                          ).toLocaleDateString()
                        : filterName.replaceAll("_", " ") +
                          " " +
                          item.split("=")[1]

                      /* item.replace("=", " : ")*/
                    }
                    <span className="position-absolute start-100 translate-middle badge rounded-pill bg-info">
                      x<span className="visually-hidden">delete filter</span>
                    </span>
                  </Button>
                ) : null;
              })
            : null}
        </div>
        <div className={"d-flex flex-row justify-content-start mb-3"}></div>
      </div>
      <Table>
        <thead>
          <tr>
            {columnNames.map((name) => (
              <th key={name}>{name}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((object) => (
            <tr key={type === "users" ? object.id : object.userId}>
              {columnNames.map((columnName) => {
                const value = object[columnName];
                return (
                  <td key={columnName} style={{ maxWidth: "40px" }}>
                    {columnName === "generatedAt" ? (
                      new Date(parseInt(value)).toLocaleString()
                    ) : value === undefined ? (
                      "-"
                    ) : String(value).startsWith("https") ? (
                      <a href={value}>Link</a>
                    ) : (
                      String(value)
                    )}
                  </td>
                );
              })}
              <td>
                <Button onClick={() => handleShowModal(object)}>Details</Button>
                {role === "ROLE_WORKER,ROLE_CRYPTO_SENDER,ROLE_SALES_MANAGER,ROLE_STAFF" ? (
                  <Button
                    className={"btn-secondary mg-10"}
                    onClick={() => {
                      handleSalaryModal(object);
                    }}
                  >
                    Pay
                  </Button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1.5rem 0",
          }}
        >
          <select
            className=" mb-4 selectpage border me-1"
            value={size}
            onChange={(e) => {
              setSize(e.target.value);
              /*  setPageSize(e.target.value);*/
            }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <Pagination className="" style={{ flexWrap: "wrap" }}>
            <Pagination.Item
              className={currentPage !== 0 ? "page-item" : "page-item disabled"}
              onClick={() =>
                currentPage !== 0 ? setPage(currentPage - 1) : null
              }
            >
              <i className="bi bi-chevron-left"></i>
            </Pagination.Item>

            {pageNumbersArray.map((number) => {
              return (
                <Pagination.Item
                  key={number}
                  onClick={() => setPage(number - 1)}
                  className={
                    currentPage + 1 === number
                      ? "page-item active"
                      : "page-item"
                  }
                >
                  {number}
                </Pagination.Item>
              );
            })}

            <Pagination.Item
              className={
                currentPage !== pageNumbersArray.length - 1
                  ? "page-item"
                  : "page-item disabled"
              }
              onClick={() =>
                currentPage !== pageNumbersArray.length
                  ? setPage(currentPage + 1)
                  : null
              }
            >
              <i className="bi bi-chevron-right"></i>
            </Pagination.Item>
          </Pagination>
        </div>
      </div>
      <Modal
        show={salaryModal}
        onHide={() => setSalaryModal(false)}
        centered="true"
        id=""
      >
        <Modal.Header>
          <h6 className="modal-title">Add Salary</h6>
          <Button
            variant=""
            type="button"
            onClick={() => setSalaryModal(false)}
          >
            <span aria-hidden="true" className="text-dark">
              <i className="fe fe-close"></i>
            </span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <SalaryModal closeModal={setSalaryModal} worker={selectedObject} />
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedObject ? (
            <TreeView
              data={selectedObject}
              editableFields={editableFields}
              defaultExpanded={false}
              handleSave={update}
              setShowModal={setShowModal}
              type={type}
            />
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {/*{editableFields.length > 0 &&
            (editMode ? (
              <Button variant="secondary" onClick={handleSave}>
                {loading ? <SmallLoader /> : "Save"}
              </Button>
            ) : (
              <Button variant="primary" onClick={handleEditField}>
                Edit
              </Button>
            ))}*/}
          {error && <p>{error}</p>}
          <Button onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ObjectTable;
