import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Button, Col, FormGroup, Form, Row } from "react-bootstrap";
import {
  getInfoBoxMessages,
  removeInfoBoxMessages,
  updateInfoBoxMessages,
} from "../../../api/config/config";

export const FormEditorstyle1 = () => {
  const [color, setColor] = useState("#D9D9D9");
  const [showOnDeal, setShowOnDeal] = useState(false);
  const { handleSubmit, control } = useForm({
    mode: "onChange",
  });
  const handleSubmitOnClick = ({ editor_content }) => {
    console.log("editor_content ==> ", editor_content);
  };
  const [currentContent, setCurrentContent] = useState("");
  const [description, setDescription] = useState({
    htmlValue: currentContent,
    editorState: EditorState.createEmpty(),
  });

  useEffect(() => {
    getInfoBoxMessages().then((res) => {
      console.log("res ==> ", res);
      setCurrentContent(res.data?.message);
    });
  }, []);

  function handleSave() {
    updateInfoBoxMessages({
      message: description.htmlValue,
      color: color,
      showOnDealPage: showOnDeal,
    }).then((res) => {
      console.log("res ==> ", res);
    });
  }
  function handleRemove() {
    removeInfoBoxMessages().then((res) => {
      console.log("res ==> ", res);
    });
  }

  const onEditorStateChange = (editorValue) => {
    const editorStateInHtml = draftToHtml(
      convertToRaw(editorValue.getCurrentContent())
    );

    setDescription({
      htmlValue: editorStateInHtml,
      editorState: editorValue,
    });
  };
  return (
    <section>
      <form
        className="richText br-5"
        onSubmit={handleSubmit(handleSubmitOnClick)}
      >
        <Controller
          name="editor_content"
          control={control}
          defaultValue=""
          render={(props) => (
            <Editor
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                colorPicker: { inDropdown: true },
              }}
              {...props}
              editorState={description.editorState}
              onEditorStateChange={onEditorStateChange}
            />
          )}
        />
      </form>
      <Col className={"editor-footer"}>
        <Row className="align-top">
          <div>Text color</div>
          <div>
            <input
              className="wd-25 ht-25 input-color-picker color-primary-light"
              defaultValue="#38cab3"
              id="colorID"
              value={color}
              onInput={(e) => {
                setColor(e.target.value);
              }}
              type="color"
              data-id="bg-color"
              data-id1="bg-hover"
              data-id2="bg-border"
              data-id7="transparentcolor"
              name="lightPrimary"
            />
          </div>
          <Button
            className="btn btn-warning btn-sm"
            onClick={() => setColor("#D9D9D9")}
          >
            Reset
          </Button>{" "}
          <FormGroup className="form-group mb-0 justify-content-start align-top">
            <div className="checkbox">
              <div className="custom-checkbox custom-control">
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Form.Control
                  type="checkbox"
                  data-checkboxes="mygroup"
                  className="custom-control-input"
                  id="checkbox-2"
                  checked={showOnDeal}
                  onChange={(e) => {
                    setShowOnDeal(e.target.checked);
                  }}
                />
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Form.Label
                  htmlFor="checkbox-2"
                  className="custom-control-label mt-1"
                >
                  Show on Deal Page
                </Form.Label>
              </div>
            </div>
          </FormGroup>
        </Row>

        <div>{description.htmlValue}</div>
        <div>{color}</div>
        <div>
          {showOnDeal ? "Show on Deal Page" : "Don't show on Deal Page"}
        </div>
        <Row
          className={""}
          style={{
            justifyContent: "space-between",
          }}
        >
          <Button
            type="submit"
            style={{
              alignSelf: "flex-end",
            }}
            onClick={handleSave}
          >
            Submit
          </Button>
          <Button
            type="submit"
            style={{
              alignSelf: "flex-end",
            }}
            variant={"danger"}
            onClick={handleRemove}
          >
            Remove
          </Button>
        </Row>
      </Col>
    </section>
  );
};
