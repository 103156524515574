import React, { useState } from "react";
import { Button } from "react-bootstrap";

const TreeView = ({
  data,
  defaultExpanded,
  editableFields,
  handleSave,
  setShowModal,
  type,
}) => {
  const [expanded, setExpanded] = useState({});
  const [editing, setEditing] = useState({});
  const [editableValues, setEditableValues] = useState({});

  const renderValue = (value) => {
    return String(value);
  };
  const toggleExpanded = (key) => {
    setExpanded({
      ...expanded,
      [key]: !expanded[key],
    });
  };

  const toggleEditing = (key) => {
    setEditing({
      ...editing,
      [key]: !editing[key],
    });
  };

  const handleInputChange = (e, field) => {
    setEditableValues({ ...editableValues, [field]: e.target.value });
  };
  const handleSaveChanges = () => {
    const payload = {};
    editableFields.forEach((field) => {
      if (type === "users") {
        payload[field] = editableValues[field]
          ? editableValues[field]
          : data[field];
      } else if (type === "referrals") {
        payload["percentages"] = {
          ...payload["percentages"],
          [field]: editableValues[field]
            ? editableValues[field]
            : data["percentages"][field],
        };
      } else if (type === "payment-methods") {
        payload[field] = editableValues[field]
          ? editableValues[field]
          : data[field];
      }
    });
    handleSave(
      type === "users"
        ? data.id
        : type === "referrals"
        ? data.userId
        : type === "payment-methods"
        ? data.name.replace(" ", "_").toUpperCase()
        : "",
      payload
    )
      .then((res) => {
        setEditing({});
        setShowModal(false);
      })
      .catch((err) => {});
  };
  const renderTree = (node, path = []) => {
    return (
      <ul>
        {Object.entries(node).map(([key, value]) => {
          const currentPath = [...path, key];
          if (typeof value === "object") {
            return (
              <li key={key} style={{ fontSize: "16px", listStyleType: "none" }}>
                <h5
                  className={"mg-r-10"}
                  style={{ textTransform: "capitalize" }}
                >
                  {key}:{" "}
                  <Button
                    onClick={() => toggleExpanded(currentPath.join("."))}
                    variant=""
                    className="btn btn-outline-info btn-rounded btn-sm my-0"
                  >
                    {expanded[currentPath.join(".")] ? "-" : "+"}
                  </Button>
                  {expanded[currentPath.join(".")] &&
                    renderTree(value, currentPath)}
                </h5>
              </li>
            );
          } else {
            return (
              <li key={key} style={{ fontSize: "16px", listStyleType: "none" }}>
                <h5
                  className={"mg-r-10"}
                  style={{ textTransform: "capitalize" }}
                >
                  {key}:{" "}
                  <span style={{ fontWeight: "300" }}>
                    {editableFields.includes(key) ? (
                      <>
                        {editing[key] ? (
                          <input
                            type="text"
                            defaultValue={value}
                            className={"mg-r-10"}
                            onChange={(e) => handleInputChange(e, key)}
                          />
                        ) : (
                          <span
                            style={{ textTransform: "none" }}
                            className={"mg-r-10"}
                          >
                            {String(value)}
                          </span>
                        )}
                        <Button
                          variant=""
                          className="btn btn-outline-primary  btn-sm mg-r-10"
                          onClick={() => {
                            editing[key]
                              ? handleSaveChanges()
                              : toggleEditing(key);
                          }}
                        >
                          {editing[key] ? "Save" : "Edit"}
                        </Button>
                        {editing?.[key] && (
                          <Button
                            variant=""
                            className="btn btn-outline-danger  btn-sm"
                            onClick={() => toggleEditing(key)}
                          >
                            Cancel
                          </Button>
                        )}
                      </>
                    ) : (
                      <span
                        style={{ textTransform: "none" }}
                        className={"mg-r-10"}
                      >
                        {key === "registeredAt" ||
                        key === "verificationCodeExpiredAt" ||
                        key === "createdAt" ||
                        key === "signInLastTimeAt" ||
                        key === "verifiedAt" ||
                        key === "generatedAt"
                          ? (() => {
                              const date = new Date(value);
                              return !isNaN(date.getTime())
                                ? date.toLocaleString()
                                : "Invalid date format";
                            })()
                          : renderValue(value)}
                      </span>
                    )}
                  </span>
                </h5>
              </li>
            );
          }
        })}
      </ul>
    );
  };
  return <div>{renderTree(data)}</div>;
};

export default TreeView;
