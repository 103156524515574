import fetcher from "../../utils/Fetcher";

export class Referral {
  userId!: string;
  referredBy!: string;
  referralCode!: string;
  percentages!: string[];
  referralRewardsBalance!: string;
  totalReferrals!: string;
  weekReferrals!: string;
  referralRewardsWallet!: string;
}

export class ReferralFilters {}
//@ts-ignore
export async function getReferrals(size, page, search, filter, sort) {
  return await fetcher(
    "get",
    search
      ? "/referrals/search?value=" + search
      : "/referrals?sort=totalRewards," +
          sort +
          "&size=" +
          size +
          "&page=" +
          page +
          filter
  );
}

export async function getFilters() {
  return await fetcher("get", "/filters/REFERRALS");
}
