import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {
  getFilters,
  getUsers,
  UserFilters,
} from "../../../api/users/getUsers.tsx";
import { updateUser } from "../../../api/users/updateUser";
import TableView from "../EmptyPage/TableView";
import Dividends from "./Dividends";

function Users({ role, disableSearch }) {
  const [users, setUsers] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [sort, setSort] = React.useState("desc");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    getFilters().then((response) => {
      console.log(response);

      let filters = new UserFilters();
      filters.emailVerified = ["true", "false"];
      filters.countryCodes = response.countryCodes;
      filters.kycStatuses = response.kycStatuses;
      filters.providers = response.providers;
      filters.locked = ["true", "false"];

      setFilters(filters);
    });
  }, []);

  useEffect(() => {
    getUsers(pageSize, currentPage, search, filter, sort, role).then(
      (response) => {
        /*  let usersArray = [];
                              response.content.forEach((el) => {
                                  let user = new User();
                                /!*  user.id = el.id;
                                  user.type =
                                      <p className={el.type === "buy" ? "text-primary tx-medium" : "text-secondary tx-medium"}>{el.type.toUpperCase()}</p> /!*el.type*!/
                                  user.user_Number = el.userNumber;
                                  user.user_Code = el.userCode;
                                  user.income = user.type === "buy" ? el.fiatValue + " " + el.fiatCode.toUpperCase() : el.cryptoValue + " " + el.cryptoCode.toUpperCase() + " " + el.network.toUpperCase();
                                  user.outcome = user.type === "buy" ? el.cryptoValue + " " + el.cryptoCode.toUpperCase() + " " + el.network.toUpperCase() : el.fiatValue + " " + el.fiatCode.toUpperCase();
                                  user.status = <p
                                      className={el.status === "closed" ? "text-success tx-medium" : el.status === "created" ? "text-warning tx-medium" : el.status === "expired" ? "text-info tx-medium" : ""}>{el.status}</p>;


                                  user.company = el.paymentMethodCode;
                                  user.created_At = new Date(el.createdAt).toLocaleString();
                                  user.details = el.paymentDescription;
                                  user.wallet = el.wallet;
                                  user.gel_Rate = el.gelRate;
                                  user.rating = el.mark;
                                  user.comment = el.feedbackComment;
                                  user.processing_status = el.processingStatus;
                                  user.paid_At = el.paidAt;
                                  user.initial_Amount = el.originalCryptoValue;*!/
                                  usersArray.push(user);
                              });*/
        setUsers(response.content);

        if (users.length === 0) {
          // setusers(response.content);

          setUsers(response.content);
          setTotalElements(response.totalElements);
          setTotalPages(response.totalPages);
        } else if (users.length > 0) {
          console.log("users.length > 0");
          setTotalElements(response.totalElements);
          setTotalPages(response.totalPages);
          return setUsers(response.content);
          /*  setusers(users.concat(response.content));
                                        setTotalElements(response.totalElements);
                                        setTotalPages(response.totalPages);*/
        } else {
          console.log(users);
          console.log(totalElements);
          console.log(totalPages);
        }
      }
    );
  }, [
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    search,
    setSearch,
    filter,
    setFilter,
    sort,
    setSort,
    role,
  ]);

  async function updateUserInfo(userId, data) {
    await updateUser(userId, data).then((response) => {
      if (response.status === 200) {
        getUsers(pageSize, currentPage, search, filter, sort, role).then(
          (response) => {
            setUsers(response.content);
          }
        );
      }
    });
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Users : {totalElements}
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" href="#">
              Transactions
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumb-item "
              active
              aria-current="page"
            >
              Users
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb -->

					<!-- row --> */}
      <Row>
        <Col md={12} xl={12} xs={12} sm={12}>
          <Card>
            <Card.Body>
              <div>
                {/*  <Savetable data={users} headers={["id", "type", "status", "userNumber", "paymentMethodCode"]}/>*/}
                {/*<BasicTable
                  data={users}
                  headers={[
                    {
                      Header: "id",
                      accessor: "id",
                      className: "borderrigth",
                    },
                    {
                      Header: "locked",
                      accessor: "locked",
                      className: "borderrigth",
                    },
                    {
                      Header: "first name",
                      accessor: "firstName",
                      className: "borderrigth",
                    },
                    {
                      Header: "last name",
                      accessor: "lastName",
                      className: "borderrigth",
                    },
                    {
                      Header: "email",
                      accessor: "email",
                      className: "borderrigth",
                    },

                    {
                      Header: "Email Verified",
                      accessor: "emailVerified",
                      className: "borderrigth",
                    },
                    {
                      Header: "registered at",
                      accessor: "registeredAt",
                      className: "borderrigth",
                    },
                    {
                      Header: "Provider",
                      accessor: "provider",
                      className: "borderrigth",
                    },
                    {
                      Header: "KYC status",
                      accessor: "kycStatus",
                      className: "borderrigth",
                    },
                    {
                      Header: "Last visit",
                      accessor: "signInLastTimeAt",
                      className: "borderrigth",
                    },
                  ]}
                  size={pageSize}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setPage={setCurrentPage}
                  setSize={setPageSize}
                  setSearch={setSearch}
                  filters={filters}
                  currentFilter={filter}
                  setFilter={setFilter}
                  sort={sort}
                  setSort={setSort}
                  searchOptions={"User ID"}
                  editableFields={[
                    "personnelSellFee",
                    "personnelBuyFee",
                    "locked",
                    "kycApplicantId",
                  ]}
                />*/}

                <TableView
                  data={users}
                  columnNames={["id", "email", "firstName", "lastName"]}
                  editableFields={[
                    "personnelBuyFee",
                    "personnelSellFee",
                    "locked",
                    "kycApplicantId",
                  ]}
                  update={updateUserInfo}
                  size={pageSize}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setPage={setCurrentPage}
                  setSize={setPageSize}
                  setSearch={disableSearch ? null : setSearch}
                  filters={filters}
                  currentFilter={filter}
                  setFilter={setFilter}
                  sort={sort}
                  setSort={setSort}
                  searchOptions={"User ID"}
                  type={"users"}
                  role={role}
                  disableSearch={disableSearch}
                />
              </div>
              {role === "ROLE_ADMIN" ? (
                <>
                  <Button
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Pay Dividends
                  </Button>
                </>
              ) : null}
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Dividends</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Dividends users={users} closeModal={setShow} />
                </Modal.Body>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <!-- row closed --> */}
    </div>
  );
}

Users.propTypes = {};

Users.defaultProps = {};

export default Users;
